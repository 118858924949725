import Store from './Store';
import moment from 'moment';
import Utils from "./Utils";

class XcelLoader {
	new_rus_export_tasks(tasks){		
		let j = 0;
		let results = [];		
		for(let d of tasks) {
			j++;	
		    let elem = {		            
				'Document Name': d.document_name !== null ? d.document_name : '',
				'Company': d.company_name  !== null ? d.company_name : '',
				'Document Types' : d.document_types  !== null ? d.document_types : '',
				'Regulator Name' : d.regulator_name  !== null ? d.regulator_name : '',
				'Issue Date' : d.issue_date  !== null ? d.issue_date : '',					
				'Added Date' : d.added_date  !== null ? d.added_date : ''
		    }; 
			
			let keyDates = d.keyDates !== null ? d.keyDates : [];
			let m = 0;
			for (let k of keyDates) {
				m++;
				elem["Key Date " + m] = moment(k.date).format('DD/MM/YY');
				elem["Key Date " + m + " Description"] = k.description;
			}
			let externalLinks = d.externalLinks !== null ? d.externalLinks : [];
			let n = 0;
			for (let e of externalLinks) {
				n++;
				elem["External Link " + n] = e.url;
				elem["External Link " + n + " Description"] = e.description;
			}
		    results.push(elem)
		}
		return results;
	}

	inpro_rus_export_tasks(tasks){		
		let j = 0;
		let results = [];	
		for(let d of tasks) {
			j++;	
		    let elem = {		            
				'Document Name': d.document_name,
				'Company': d.company_name,
				'Document Types' : d.document_types,
				'Regulator Name' : d.regulator_name,
				'Issue Date' : d.issue_date,					
				'Added Date' : d.added_date,
				'Follow-up Task' : d.followup_tasks,
				'Status' : d.status_value,
		    }; 
			let keyDates = d.keyDates;
			let m = 0;
			for (let k of keyDates) {
				m++;
				elem["Key Date " + m] = moment(k.date).format('DD/MM/YY');
				elem["Key Date " + m + " Description"] = k.description;
			}
			let externalLinks = d.externalLinks;
			let n = 0;
			for (let e of externalLinks) {
				n++;
				elem["External Link " + n] = e.url;
				elem["External Link " + n + " Description"] = e.description;
			}
		    results.push(elem)
		}
		return results;
	}
	dismiss_rus_export_tasks(tasks){		
		let j = 0;
		let results = [];	
		for(let d of tasks) {
			j++;	
		    let elem = {		            
				'Document Name': d.document_name !== null ? d.document_name : '',
				'Company': d.company_name !== null ? d.company_name : '',
				'Document Types' : d.document_types !== null ? d.document_types : '',
				'Regulator Name' : d.regulator_name !== null ? d.regulator_name : '',
				'Issue Date' : d.regulator_published_date !== null ? d.regulator_published_date : '',					
				'Added Date' : d.added_date !== null ? d.added_date : '',
				'Dismissed Date' : d.dismiss_date !== null ? d.dismiss_date : '',
				'Dismissed By' : d.dismissed_by !== null ? d.dismissed_by : '',
				'Reason for Dismissal' : d.dismissNote !== null ? d.dismissNote : ''				
		    }; 
			let keyDates = d.keyDates !== null ? d.keyDates : [];
			let m = 0;
			for (let k of keyDates) {
				m++;
				if(k.date.includes("T")){
					elem["Key Date " + m] = moment(k.date).format('DD/MM/YY');
				}else{
					elem["Key Date " + m] = moment(k.date, 'DD/MM/YYYY').format('DD/MM/YY');
				}
				elem["Key Date " + m + " Description"] = k.description;
			}
			let externalLinks = d.externalLinks !== null ? d.externalLinks : [];
			let n = 0;
			for (let e of externalLinks) {
				n++;
				elem["External Link " + n] = e.url;
				elem["External Link " + n + " Description"] = e.description;
			}
			// console.log("elem==>", elem)
		    results.push(elem)
		}
		return results;
	}
	custom_rus_export_tasks(tasks){		
		let j = 0;
		let results = [];		
		for(let d of tasks) {
			j++;	
		    let elem = {		            
				'Document Name': d.document_name,
				'Company': d.company_name,
				'Document Types' : d.document_types,
				'Regulator Name' : d.regulator_name,
				'Issue Date' : d.issue_date,					
				'Added Date' : d.added_date							
		    }; 
			let keyDates = d.keyDates;
			let m = 0;
			for (let k of keyDates) {
				m++;
				elem["Key Date " + m] = moment(k.date).format('DD/MM/YY');
				elem["Key Date " + m + " Description"] = k.description;
			}
			let externalLinks = d.externalLinks;
			let n = 0;
			for (let e of externalLinks) {
				n++;
				elem["External Link " + n] = e.url;
				elem["External Link " + n + " Description"] = e.description;
			}
		    results.push(elem)
		}
		return results;
	}
	saved_rus_export_tasks(tasks){		
		let j = 0;
		let results = [];			
		for(let d of tasks) {
			j++;	
		    let elem = {		            
				'Status': d.status_value,
				'Document Name': d.document_name,
				'Company': d.company_name,
				'Document Types' : d.document_types,
				'Regulator Name' : d.regulator_name,
				'Last Saved Date' : d.last_save_date,					
				'Added Date' : d.added_date							
		    }; 
			let keyDates = d.keyDates;
			let m = 0;
			for (let k of keyDates) {
				m++;
				elem["Key Date " + m] = moment(k.date).format('DD/MM/YY');
				elem["Key Date " + m + " Description"] = k.description;
			}
			let externalLinks = d.externalLinks;
			let n = 0;
			for (let e of externalLinks) {
				n++;
				elem["External Link " + n] = e.url;
				elem["External Link " + n + " Description"] = e.description;
			}
		    results.push(elem)
		}
		return results;
	}
	my_rus_export_tasks(tasks){		
		let j = 0;
		let results = [];			
		for(let d of tasks) {
			j++;	
		    let elem = {		            
				'Document Name': d.document_name,
				'Company': d.company_name,
				'Document Types' : d.document_types,
				'Regulator Name' : d.regulator_name,
				'Issue Date' : d.issue_date,					
				'Assigned Date' : d.assigned_date,						
				'Added Date' : d.added_date						
		    }; 
			let keyDates = d.keyDates;
			let m = 0;
			for (let k of keyDates) {
				m++;
				elem["Key Date " + m] = moment(k.date).format('DD/MM/YY');
				elem["Key Date " + m + " Description"] = k.description;
			}
			let externalLinks = d.externalLinks;
			let n = 0;
			for (let e of externalLinks) {
				n++;
				elem["External Link " + n] = e.url;
				elem["External Link " + n + " Description"] = e.description;
			}
		    results.push(elem)
		}
		return results;
	}
	my_tasks_export_tasks(tasks){		
		let j = 0;
		let results = [];		
		for(let d of tasks) {
			j++;	
		    let elem = {		            
				'Task': d.task,
				'Regulatory Change': d.regulatory_change,
				'Assigned To' : d.assigned_to,
				'Assigned Date' : d.assigned_date,
				'Due Date' : d.assigned_duedate,					
				'Added Date' : d.added_date,
				'Status' : d.status_value				
		    }; 
		    results.push(elem)
		}
		return results;
	}

	rcm_register_export_tasks(tasks){		
		let j = 0;
		let module_config = Store.getStoreData('module_config');
		let results = [];		
		for(let d of tasks) {
			j++;	
		    let elem = {		            
				'Status': d.status,
				'Name': d.name,
				'Type' : d.typeValue,
				'Regulator' : d.regulator				
		    }; 
			if('general' in module_config && module_config.general.enable_tag_management === true){
				elem["Tag(s)"] = d.tags_name;
			}
			elem["Info"] = d.sharedinfopeople;
			elem["Shared - Info"] = d.sharedinfo;
			elem["Action"] = d.sharedactionpeople;
			elem["Shared - Action"] = d.sharedaction;
			elem["Follow Up Task"] = d.followuptasks;
			elem["Regulator Published Date"] = d.regulator_published_date;

			let summarytext = d.summary;
			if(d.summary === null){
				summarytext = '';
			}
			// console.log("summarytext==>", summarytext === null, "id==>", d.id)
            if (Utils.isBase64(summarytext)) {
				summarytext = window.decodeURIComponent(atob(summarytext));
            }
            let regex = /(<([^>]+)>)/gi;
			elem["Summary"] = summarytext.replace(regex, "");

			elem["Dismissal Date"] = d.dismissDate;
			elem["Dismissed By"] = d.dismissedBy;
			elem["Reason for Dismissal"] = d.dismissNote;
			
			let keyDates = d.keyDates === null ? [] : d.keyDates;
			let m = 0;
			for (let k of keyDates) {
				m++;
				// console.log("===>", k.date.includes("T"))
				if(k.date.includes("T")){
					elem["Key Date " + m] = moment(k.date).format('DD/MM/YY');
				}else{
					elem["Key Date " + m] = moment(k.date, 'DD/MM/YYYY').format('DD/MM/YY');
				}				
				elem["Key Date " + m + " Description"] = k.description;
			}
			let externalLinks = d.externalLinkall === null ? [] : d.externalLinkall;
			let n = 0;
			for (let e of externalLinks) {
				n++;
				elem["External Link " + n] = e.url;
				elem["External Link " + n + " Description"] = e.description;
			}		
		    results.push(elem)
		}
		return results;
	}
}
export default new XcelLoader();
