import React, {Component} from "react";
import styled from "styled-components";
import CSLTable from '../../../Common/MCCTable'
import CSLLoader from '../../../Common/CSLLoader';
import Store from '../../../../Common/Store';
import moment from 'moment';
import {IoIosArrowUp, IoIosArrowDown, IoIosArrowBack} from 'react-icons/io';
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import APICall from './../../../../Common/APICall';
import AlertBox from './../../../Common/AlertBox';
import AutoForwordFeedModal from './../../AutoForwordFeedModal';
import AutoForwordFeedModalForGC from './../../AutoForwordFeedModalForGC';
import CardLoader from './../../../../Common/CardLoader';
import IndexLoader from './../../../../Common/IndexLoader';
import RegulatoryShareModal from './../../../Modals/RegulatoryShareModal';
import RegulatoryClientModal from './../../../Modals/RegulatoryClientModal';
import DismissTaskModal from './../../DismissTaskModal';
import AutoForWardDismissTaskModal from './../../AutoForWardDismissTaskModal';
import SaveAutoForWardRuModal from './../../SaveAutoForWardRuModal';
import SaveSelectRUModal from './../../../Modals/SaveSelectRUModal';
import FollowupTaskModal from './../../FollowupTaskModal';
import EditFollowupTaskModal from './../../MGR/EditFollowupTaskModal';
import AdvancedFilterNewRus from './Filter/AdvancedFilterNewRus';
import AdvancedFilterInProRus from './Filter/AdvancedFilterInProRus';
import AdvancedFilterDisRus from './Filter/AdvancedFilterDisRus';
import AdvancedFilterCustomRus from './Filter/AdvancedFilterCustomRus';
import AdvancedFilterSaveRus from './Filter/AdvancedFilterSaveRus';
import AdvancedFilterMyRus from './Filter/AdvancedFilterMyRus';
import AdvancedFilterMyTasks from './Filter/AdvancedFilterMyTasks';
import ClipLoader from "react-spinners/ClipLoader";
import ApiContext from "../../../contexts/ApiContext";

import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import XcelLoader from './../../../../Common/XcelLoader';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const FilterContainer = styled.div`
  width: 100%;
  height: 88px;
  padding: 2px 2px 2px 2px;
  background-color: #e7f0f7;
  box-sizing: border-box;
`;
const FilterInnerContainer = styled.div`  
  display: flex;
  float: right;
  padding-top: 24px;
  padding-right: 2%;
`;

const FilterInnerContainerRight= styled.div`
    width: 63%;    
    display: inline-block;
    float: right;
    padding-top: 27px;
`;

const FilterInnerContainerLeft = styled.div`
    width: 50%;
    display: inline-block;
    float: left;
    padding-top: 7px;
    padding-left: 2%;
`;
const PendingContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
`;
const PendingInnerContainer = styled.div`
    display: block;
    box-sizing: border-box;
    background-color: #ffffff;    
    padding: 30px;
`;
const GeneralInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const MRButtonDel = styled.button`
    box-sizing: border-box;
    background-color: #DB6170;
    color: #ffffff;
    border: 1px solid #DB6170;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    cursor: pointer;
`;
const EmailTopHeader = styled.div`
    font-weight: 600;	
	margin-top: 5px;
	width:100%;
	height: 50px;
    padding: 15px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;    
    box-sizing: border-box;
    background-color: #1E3E62;
    color: #ffffff;
`;

const Rectangle = styled.div`
    width: 42px;
    height: 42px;
    padding: 2px 2px 2px 2px;
    border-radius: 3px;
    background-color: #ffffff;
    box-sizing: border-box;
    margin-top: 13px;
`;
const Overdue = styled.span`
  height: 15px;
  width:15px;
  background-color: #ff0000;  
  margin-right:15px;
  opacity: 1 ; 
`;
const Pending = styled.span`
  height: 15px;
  width:15px;
  background-color: #5656AA;
  margin-right:15px;  
  opacity: 1 ; 
`;
const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 4px 9px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 4px 9px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const Saved = styled.span`
  height: 15px;
  width:15px;
  background-color: rgb(27, 52, 81); 
  margin-right:15px; 
  opacity: 1;
`;
const InProgress = styled.span`
  height: 15px;
  width:15px;
  background-color: #FFD11A; 
  margin-right:15px; 
  opacity: 1;
`;
const Complete = styled.span`
  height: 15px;
  width:15px;
  background-color: #00ff00;  
  margin-right:15px;
  opacity: 1 ; 
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 130%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  //left: -2%;
  top: 0px;
  z-index: 1000;
  opacity: 0.8;
`;
const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #F2F2F2;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin-right: 3px;
    margin-top: 19px;
    cursor: pointer;
`;
const ProgressContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 400px;
    position: fixed;
    top: 80px;
    text-align: center;
    left: calc(50vw - 200px);
    z-index: 1008;
    color:  #143151;  
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
`;

const ProgressBarTotal = styled.div`
    width: 380px;
    height: 14px;
    background-color: white;
    border: 1px solid #04ADA8; 
    margin-top: 8px;
    margin-bottom: 8px;
`;

const ProgressBar = styled.div`
    height: 100%;
    background-color: blue;
    float: left;
`;

class AllRus extends Component {
  state = {
    ready: false,
    doctype: {},
    isLoadedIcon: false,
    autoForwordFeedsAndRU: [],
    pendingRCtasks: [],
    dismissFeedsAndRUtasks: [],
    newRCtasks: [],
    saveRCtasks: [],
    shareWithMeRCTasks: [],
    followupRCtasks: [],
    is_show_pending_toggle: true,
    showinRole: false,
    show_table_filter:{
        Automated_News_Feed: true
    },
    alert_param: null,
    modal_view: null,
    isDismissClickOnTable: false,
    isShowDismissalDropdown: false,

    isShowfilter_new_rus: false,
    regulatorObject: {},
    isShowfilter_inpro_rus: false,
    isShowfilter_dis_rus: false,
    isShowfilter_cus_rus: false,
    isShowfilter_save_rus: false,
    isShowfilter_my_rus: false,
    isShowfilter_my_tasks: false,

    isShowMultipleDismiss: false,
    rowcheckedMultiDiss: false,
    mutipleDelObject: {},
    show_progress: false,
    dismissFeed_count: 0,
    stop_polling: false,

    //customRU
    isShowMultipleDismiss_cRU: false,
    rowcheckedMultiDiss_cRU: false,
    mutipleDelObject_cRU: {},
    show_progress_cRU: false,
    dismissFeed_count_cRU: 0,
    stop_polling_cRU: false,
    isAfterDismiss: false
  };

  stop_polling = false
  static contextType = ApiContext;

  componentDidMount(){
    const documentType = Store.getStoreData('rutypenameAll');
    let regulatorObject = Store.getStoreData('ragulatorsKnowAs');
    let doctype = {};
    for(let i=0; i < documentType.length; i++) {
        doctype[documentType[i].id] = documentType[i].documentname;
    }

    let autoForwordFeedsAndRU = Store.getStoreData('autoForwordFeedsAndRU_update');
    let pendingRCtasks = Store.getStoreData('pendingRCtasks_update');
    let dismissFeedsAndRUtasks = Store.getStoreData('dismissFeedsAndRUtasks_update');
    let newRCtasks = Store.getStoreData('newRCtasks_update');
    let saveRCtasks = Store.getStoreData('saveRCtasks_update');
    let shareWithMeRCTasks = Store.getStoreData('shareWithMeRCTasks_update');
    let followupRCtasks = Store.getStoreData('followupRCtasks_update');
    let autoForwordFeedsAndRU_updated = [];
    let pendingRCtasks_updated = [];
    let dismissFeedsAndRUtasks_updated = [];
    let newRCtasks_updated = [];
    let saveRCtasks_updated = [];
    let shareWithMeRCTasks_updated = [];
    let followupRCtasks_updated = [];
    let filter_followupRCtasks = [];
    if('regName' in this.props && this.props.regName !== ''){
        for(let auto of autoForwordFeedsAndRU){
            if(auto[8] in regulatorObject && regulatorObject[auto[8]] === this.props.regName){
                autoForwordFeedsAndRU_updated.push(auto);
            }
        }
        for(let pen of pendingRCtasks){
            if(pen[8] in regulatorObject && regulatorObject[pen[8]] === this.props.regName){
                pendingRCtasks_updated.push(pen);
            }
        }
        for(let dis of dismissFeedsAndRUtasks){
            if(dis[8] in regulatorObject && regulatorObject[dis[8]] === this.props.regName){
                dismissFeedsAndRUtasks_updated.push(dis);
            }
        }
        for(let ne of newRCtasks){
            if(ne[8] in regulatorObject && regulatorObject[ne[8]] === this.props.regName){
                newRCtasks_updated.push(ne);
            }
        }
        for(let sav of saveRCtasks){
            if(sav[8] in regulatorObject && regulatorObject[sav[8]] === this.props.regName){
                saveRCtasks_updated.push(sav);
            }
        }
        for(let sha of shareWithMeRCTasks){
            if(sha[8] in regulatorObject && regulatorObject[sha[8]] === this.props.regName){
                shareWithMeRCTasks_updated.push(sha);
            }
        }
        for(let fol of followupRCtasks){
            if(fol[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){
                if(fol[6] in regulatorObject && regulatorObject[fol[6]] === this.props.regName){
                    followupRCtasks_updated.push(fol);
                }
            }
            if(fol[6] in regulatorObject && regulatorObject[fol[6]] === this.props.regName){
                filter_followupRCtasks.push(fol);
            }
        }
    }else{
        autoForwordFeedsAndRU_updated = Store.getStoreData('autoForwordFeedsAndRU_update');
        pendingRCtasks_updated = Store.getStoreData('pendingRCtasks_update');
        dismissFeedsAndRUtasks_updated = Store.getStoreData('dismissFeedsAndRUtasks_update');
        newRCtasks_updated = Store.getStoreData('newRCtasks_update');
        saveRCtasks_updated = Store.getStoreData('saveRCtasks_update');
        shareWithMeRCTasks_updated = Store.getStoreData('shareWithMeRCTasks_update');

        for(let fol of followupRCtasks){
            if(fol[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){
                followupRCtasks_updated.push(fol);
            }
        }
        // followupRCtasks_updated = Store.getStoreData('followupRCtasks_update');
        filter_followupRCtasks = Store.getStoreData('followupRCtasks_update');
    }

    Store.updateStore('filterNewRus', autoForwordFeedsAndRU_updated)
    Store.updateStore('filterInProgressRus', pendingRCtasks_updated)
    Store.updateStore('filterDismissRus', dismissFeedsAndRUtasks_updated)
    Store.updateStore('filterCustomRus', newRCtasks_updated)
    Store.updateStore('filterSavedRus', saveRCtasks_updated)
    Store.updateStore('filterMyRus', shareWithMeRCTasks_updated)
    Store.updateStore('filterMyTasks', filter_followupRCtasks)
    Store.updateStore('filterClearMyTasks', followupRCtasks_updated)

    let role = Store.getStoreData('role');
    let showinRole = role === 'rcm_gatekeeper' ? false : true;

    console.log("isdismissrunning", Store.getStoreData('isdismissrunning'))
    console.log("mutipleDelObject", Store.getStoreData('mutipleDelObject'))

    let mutipleDelObject = {}
    let isCheck = false;
    let isAfterDismiss = false
    let isdismissrunning = Store.getStoreData('isdismissrunning')
    if(isdismissrunning !== null && isdismissrunning && Store.getStoreData('mutipleDelObject') !== null){
        mutipleDelObject = Store.getStoreData('mutipleDelObject')
        isCheck = true;
        isAfterDismiss = true;
    }

    this.setState({doctype, autoForwordFeedsAndRU: autoForwordFeedsAndRU_updated,
        pendingRCtasks: pendingRCtasks_updated,
        dismissFeedsAndRUtasks: dismissFeedsAndRUtasks_updated,
        newRCtasks: newRCtasks_updated,
        saveRCtasks: saveRCtasks_updated,
        shareWithMeRCTasks: shareWithMeRCTasks_updated,
        followupRCtasks: followupRCtasks_updated,
        regulatorObject,
        showinRole,
        mutipleDelObject,
        isAfterDismiss,
        ready: true
    })

    if(isCheck){
        this.trackShareProgress()
    }
}

componentDidUpdate(prev_props) {
    if(this.props !== prev_props && this.props.alertParam !== null) {
        const documentType = Store.getStoreData('rutypenameAll');
        let regulatorObject = Store.getStoreData('ragulatorsKnowAs');
        let doctype = {};
        for(let i=0; i < documentType.length; i++) {
            doctype[documentType[i].id] = documentType[i].documentname;
        }

        let autoForwordFeedsAndRU = Store.getStoreData('autoForwordFeedsAndRU_update');
        let pendingRCtasks = Store.getStoreData('pendingRCtasks_update');
        let dismissFeedsAndRUtasks = Store.getStoreData('dismissFeedsAndRUtasks_update');
        let newRCtasks = Store.getStoreData('newRCtasks_update');
        let saveRCtasks = Store.getStoreData('saveRCtasks_update');
        let shareWithMeRCTasks = Store.getStoreData('shareWithMeRCTasks_update');
        let followupRCtasks = Store.getStoreData('followupRCtasks_update');
        let autoForwordFeedsAndRU_updated = [];
        let pendingRCtasks_updated = [];
        let dismissFeedsAndRUtasks_updated = [];
        let newRCtasks_updated = [];
        let saveRCtasks_updated = [];
        let shareWithMeRCTasks_updated = [];
        let followupRCtasks_updated = [];
        if('regName' in this.props && this.props.regName !== ''){
            for(let auto of autoForwordFeedsAndRU){
                if(auto[8] in regulatorObject && regulatorObject[auto[8]] === this.props.regName){
                    autoForwordFeedsAndRU_updated.push(auto);
                }
            }
            for(let pen of pendingRCtasks){
                if(pen[8] in regulatorObject && regulatorObject[pen[8]] === this.props.regName){
                    pendingRCtasks_updated.push(pen);
                }
            }
            for(let dis of dismissFeedsAndRUtasks){
                if(dis[8] in regulatorObject && regulatorObject[dis[8]] === this.props.regName){
                    dismissFeedsAndRUtasks_updated.push(dis);
                }
            }
            for(let ne of newRCtasks){
                if(ne[8] in regulatorObject && regulatorObject[ne[8]] === this.props.regName){
                    newRCtasks_updated.push(ne);
                }
            }
            for(let sav of saveRCtasks){
                if(sav[8] in regulatorObject && regulatorObject[sav[8]] === this.props.regName){
                    saveRCtasks_updated.push(sav);
                }
            }
            for(let sha of shareWithMeRCTasks){
                if(sha[8] in regulatorObject && regulatorObject[sha[8]] === this.props.regName){
                    shareWithMeRCTasks_updated.push(sha);
                }
            }
            for(let fol of followupRCtasks){
                if(fol[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){
                    if(fol[6] in regulatorObject && regulatorObject[fol[6]] === this.props.regName){
                        followupRCtasks_updated.push(fol);
                    }
                }
            }
        }else{
            autoForwordFeedsAndRU_updated = Store.getStoreData('autoForwordFeedsAndRU_update');
            pendingRCtasks_updated = Store.getStoreData('pendingRCtasks_update');
            dismissFeedsAndRUtasks_updated = Store.getStoreData('dismissFeedsAndRUtasks_update');
            newRCtasks_updated = Store.getStoreData('newRCtasks_update');
            saveRCtasks_updated = Store.getStoreData('saveRCtasks_update');
            shareWithMeRCTasks_updated = Store.getStoreData('shareWithMeRCTasks_update');

            for(let fol of followupRCtasks){
                if(fol[4] !== 'COMP_RCM_FOLLOWUP_COMPLETE'){
                    followupRCtasks_updated.push(fol);
                }
            }
            // followupRCtasks_updated = Store.getStoreData('followupRCtasks_update');
        }

        let role = Store.getStoreData('role');
        let showinRole = role === 'rcm_gatekeeper' ? false : true;

        this.setState({doctype, autoForwordFeedsAndRU: autoForwordFeedsAndRU_updated,
            pendingRCtasks: pendingRCtasks_updated,
            dismissFeedsAndRUtasks: dismissFeedsAndRUtasks_updated,
            newRCtasks: newRCtasks_updated,
            saveRCtasks: saveRCtasks_updated,
            shareWithMeRCTasks: shareWithMeRCTasks_updated,
            followupRCtasks: followupRCtasks_updated,
            regulatorObject,
            showinRole,
            ready: true})
    }
}

  setShowTable = (flag, table) =>{
    console.log("flag", flag, "table", table)
    let show_table_filter = this.state.show_table_filter;
    show_table_filter[table.toString()] = flag
    this.setState({show_table_filter: show_table_filter})
}

selectdissmissRU = (event) => {
    let feedid = event.target.value;
    let autoForwordFeedsAndRU_value = this.state.autoForwordFeedsAndRU;
    let up_auto = [];
    let mutipleDelObject = this.state.mutipleDelObject;
    autoForwordFeedsAndRU_value.forEach((d) => {
        if(d[2] === feedid && d[17] === false){
            d[17] = true
            mutipleDelObject[d[2].toString()] = 1
        }else if(d[2] === feedid && d[17] === true){
            d[17] = false
            delete mutipleDelObject[d[2].toString()];
        }
        up_auto.push(d)
    })
    console.log("mutipleDelObject==>", mutipleDelObject)
    this.setState({autoForwordFeedsAndRU: up_auto, rowcheckedMultiDiss: false, mutipleDelObject})
    let allselectdissmissRUcheck = true
    for(let d of up_auto) {
        if(!d[17]){
            allselectdissmissRUcheck = false
        }
    }
    if(allselectdissmissRUcheck){
        this.setState({rowcheckedMultiDiss: true})
    }
}

selectAlldissmissRU = (event) => {
    let rowcheckedMultiDiss = this.state.rowcheckedMultiDiss;
    rowcheckedMultiDiss = rowcheckedMultiDiss ? false : true;
    let autoForwordFeedsAndRU_value = this.state.autoForwordFeedsAndRU;
    let mutipleDelObject = this.state.mutipleDelObject;
    if(rowcheckedMultiDiss) {
        let up_auto = [];
        autoForwordFeedsAndRU_value.forEach((d) => {
            d[17] = true
            up_auto.push(d)
            mutipleDelObject[d[2].toString()] = 1
        })
        this.setState({rowcheckedMultiDiss: rowcheckedMultiDiss, autoForwordFeedsAndRU: up_auto, mutipleDelObject})
    }else{
        let up_auto = [];
        autoForwordFeedsAndRU_value.forEach((d) => {
            d[17] = false
            up_auto.push(d)
            delete mutipleDelObject[d[2].toString()];
        })
        this.setState({rowcheckedMultiDiss: rowcheckedMultiDiss, autoForwordFeedsAndRU: up_auto, mutipleDelObject})
    }
}

processautoForwordFeedsData = () => {
    let autoForwordFeeds = this.state.autoForwordFeedsAndRU;
    let ret = {data: [], columns: []};
    let j = 0;
    const has_gc = Store.getStoreData('has_gc');
    const has_mutidiss = this.state.isShowMultipleDismiss;
    ret.columns =[  {'Header' : <div><input type="checkbox" checked={this.state.rowcheckedMultiDiss} onClick={this.selectAlldissmissRU}/></div>, Cell: row => (
                        <div>
                            <input type="checkbox" checked={row.original.select} value={row.original.feed_id} onClick={this.selectdissmissRU}/>
                        </div>
                    ), width: 40, filterable: false, sortable: false, headerStyle: {textAlign: 'center'}, style: {textAlign: 'center'}, show: has_mutidiss ? true : false},
                    {Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}},
                    {'Header' : 'Document Name', accessor: 'document_name', Cell: row => (
                        <div>
                            {
                                (() => {
                                    if(row.original.externalLink !== ''){
                                        return(<a target="_blank" href={row.original.externalLink}>{row.original.document_name}</a>)
                                    }else{
                                        return(<div>{row.original.document_name}</div>)
                                    }
                                })()

                            }

                        </div>
                      ), width: 750, headerStyle: {textAlign: 'left'}},
                    {Header: 'Company', accessor: 'company_name', minWidth: 150, headerStyle: {textAlign: 'left'}, show: has_gc ? true : false},
                    {Header: 'Document Types', accessor: 'document_types', minWidth: 100, headerStyle: {textAlign: 'left'}},
                    {Header: 'Regulator Name', accessor: 'regulator_name', minWidth: 100, headerStyle: {textAlign: 'left'}},
                    {Header: 'Issue Date', accessor: 'issue_date', minWidth: 80, headerStyle: {textAlign: 'center'}, style: {textAlign: 'center'}, sortMethod: (a, b) => {
                        if (a === b) {
                            return 0;
                        }
                        let adue = a.split("/");
                        const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                        let  bbdue = b.split("/");
                        const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                        return aReverse > bReverse ? 1 : -1;
                      }},
                    {Header: 'Added Date', accessor: 'added_date', minWidth: 80, headerStyle: {textAlign: 'center'}, style: {textAlign: 'center'}, sortMethod: (a, b) => {
                        if (a === b) {
                            return 0;
                        }
                        let adue = a.split("/");
                        const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                        let  bbdue = b.split("/");
                        const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                        return aReverse > bReverse ? 1 : -1;
                      }, style: {textAlign: 'center'}},
                    {'Header' : 'Action',headerStyle: {textAlign: 'center'}, width: 220, Cell: row => (
                        <div>
                            {
                            (() => {
                                if(row.original.isSaveAutoForwardData) {
                                    return (<div>
                                        <MRButtonDel >Dismiss</MRButtonDel>
                                        <MRButton >Process</MRButton>
                                    </div>)
                                }else{
                                    return (<div>
                                        <MRButtonDel onClick={this.processsdismissFeedTask(row.original.feed_id)}>Dismiss</MRButtonDel>
                                        <MRButton onClick={this.viewFeedTask(row.original.feed_id)}>Process</MRButton>
                                    </div>)
                                }
                            })()
                            }
                        </div>

                        )}
                 ];
    let gc_assoc = Store.getStoreData('gc_assoc_active_inactive');
    let gc_company = "";
    for(let d of autoForwordFeeds) {
        j++;
        gc_company = parseInt(d[1]) < 100000 ? Store.getStoreData('gc_company')[0].company_name : gc_assoc[(parseInt(d[1])%100000).toString()];
        let exterlink = d[6].length !== 0 ? d[6][0].url : "";

        let dNames = "";
        let i=0;
        for (let doc of d[7]){
            i++;
            if(doc in this.state.doctype){
                dNames += this.state.doctype[doc]
                if(i <= d[7].length - 1) {
                    dNames += ', '
                }
            }
        }

        let  regulatorObject = Store.getStoreData('ragulatorsKnowAs');
        let regulatorNamenew = "";
        if(d[8] in regulatorObject){
            regulatorNamenew = regulatorObject[d[8]];
        }
        let elem = {'index' : j,
                'id': d[0],
                'feed_id': d[2],
                'company_name' : gc_company,
                'externalLink': exterlink,
                'document_name' : d[5].replace(/’/g, "'").replace(/“/g, '"').replace(/”/g, '"'),
                'document_types' : dNames,
                'regulator_name' : regulatorNamenew.replace(/'+/g,"'"),
                'issue_date' : moment(d[9], 'YYYY/MM/DD').format('DD/MM/YYYY'),
                'isSaveAutoForwardData': d[10],
                'added_date': moment.unix(d[14]).format('DD/MM/YYYY'),
                'select': d[17],
                'externalLinks': d[6],
                'keyDates': d[18]
            };
        ret.data.push(elem);
    }
    ret.data = ret.data.sort((a,b) => {
        if (a.issue_date === b.issue_date) {
            return 0;
        }
        let adue = a.issue_date.split("/");
        const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
        let  bbdue = b.issue_date.split("/");
        const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
        return aReverse < bReverse ? 1 : -1;
    });
    let sarr = [];
    let s = 0;
    ret.data.forEach((item) => {
        s++;
        item.index = s;
        sarr.push(item);
    })
    ret.data = sarr;
    Store.updateStore('tbl_cur_newRus', ret.data)
    return ret;
}

viewFeedTask = (feedid) => (event) => {
    event.preventDefault();
    let postData = { "command" : "getFeedSelectSide" , "feed_id": feedid};
    let api = new APICall();
    this.setState({isLoadedIcon: true})
    api.command(postData, this.AddFeedtagNew(false));
}

AddFeedtagNew = (isDismissValue) => (result) => {
    console.log("result", result)
    Store.updateStore('cur_autoForwordFeeds', result)
    let role = Store.getStoreData('role');
    let moduleconfig = Store.getStoreData('module_config');
    if(role === 'manager' && Store.getStoreData('has_gc') && 'general' in moduleconfig && moduleconfig.general.assign_automated_news_feed_to_group_companies === true){
        this.setState({ modal_view: 'view_auto_forword_feeds_gc', isLoadedIcon: false, isDismissClickOnTable: isDismissValue})
    }else{
        this.setState({ modal_view: 'view_auto_forword_feeds', isLoadedIcon: false, isDismissClickOnTable: isDismissValue})
    }
}

processsdismissFeedTask = (feedid) => (event) => {
    event.preventDefault();
    if(Store.getStoreData('module_config').general.automatic_reason_for_dismissal && Store.getStoreData('module_config').general.require_reason_code_for_dismissal){
        let alert_param = {title: 'Confirm', message: 'Are you sure you want to dismiss this regulatory change?', ok_text: 'OK', cancel_text: 'Cancel', confirm: true,
        alertHandler: this.processsUpdatedismissFeedTask, stack: {isChecking: 1, feedid: feedid}}
        this.setState({alert_param: alert_param})
    }else if(Store.getStoreData('module_config').general.automatic_reason_for_dismissal === true && Store.getStoreData('module_config').general.require_reason_code_for_dismissal === false){
        let alert_param = {title: 'Confirm', message: 'Are you sure you want to dismiss this regulatory change?', ok_text: 'OK', cancel_text: 'Cancel', confirm: true,
        alertHandler: this.processsUpdatedismissFeedTask, stack: {isChecking: 2, feedid: feedid}}
        this.setState({alert_param: alert_param})
    }else if(Store.getStoreData('module_config').general.automatic_reason_for_dismissal === false && Store.getStoreData('module_config').general.require_reason_code_for_dismissal === true){
        let postData = { "command" : "getFeedSelectSide" , "feed_id": feedid};
        let api = new APICall();
        this.setState({isLoadedIcon: true, isShowDismissalDropdown: true})
        api.command(postData, this.AddFeedtagNew(true));
    }else if(Store.getStoreData('module_config').general.automatic_reason_for_dismissal === false && Store.getStoreData('module_config').general.require_reason_code_for_dismissal === false){
        let postData = { "command" : "getFeedSelectSide" , "feed_id": feedid};
        let api = new APICall();
        this.setState({isLoadedIcon: true, isShowDismissalDropdown: false})
        api.command(postData, this.AddFeedtagNew(true));
    }
}

processsUpdatedismissFeedTask = (result, stack) => {
    if(!result) {
        this.setState({alert_param: null})
        return
    }
    this.setState({alert_param: null, isLoadedIcon: true})
    let feedid = stack.feedid
    let update_autoForwordFeeds = []
    this.state.autoForwordFeedsAndRU.forEach((value) => {
        if(value[2].toString() === feedid.toString()){
            update_autoForwordFeeds.push(value);
        }
    })
    //new end
    console.log('result==>', update_autoForwordFeeds[0])
    let cur_autoForwordFeeds = update_autoForwordFeeds[0];
    let update_json_feed = cur_autoForwordFeeds[11];
    let uploaddate = new Date()
    if(update_json_feed.uploadDate !== null){
        uploaddate = new Date(update_json_feed.uploadDate);
    }
    let createdAt = cur_autoForwordFeeds[12] !== null ? cur_autoForwordFeeds[12] : uploaddate;

    let summaryValue = 'summary' in update_json_feed ? update_json_feed.summary : '';
    let tags = cur_autoForwordFeeds[13];
    let companyID = Store.getStoreData('companyid');
    let summarydata = window.decodeURIComponent(atob(summaryValue));
    let summary = summarydata;
    let id = cur_autoForwordFeeds[2];
    const issue_date = cur_autoForwordFeeds[9];

    let sections = [];
    if ("sections" in update_json_feed) {
        sections = JSON.parse(JSON.stringify(update_json_feed.sections));
        for( let i=0; i<sections.length; i++)
        {
            sections[i].display = "label";
            sections[i].content = window.decodeURIComponent(atob(sections[i].content));
        }
    }
    let documentname = cur_autoForwordFeeds[5];
    let documentTypes = cur_autoForwordFeeds[7];

    let keyDates = [];
    if("keyDates" in update_json_feed) {
        keyDates = update_json_feed.keyDates;
    }
    let externalLinks = cur_autoForwordFeeds[6];
    var regulatortypeName = cur_autoForwordFeeds[8];
    let rufeed = {};
    rufeed.tags = tags;
    rufeed.summary = btoa(window.encodeURIComponent(summary));
    rufeed.sections = sections;
    rufeed.id = id;
    rufeed.companyid = companyID;
    rufeed.documentName = documentname;
    rufeed.issueDate = issue_date;
    rufeed.createdDate = createdAt;
    rufeed.uploadDate = uploaddate;
    rufeed.modificationDate = new Date();
    rufeed.publicResponseDate = new Date();

    rufeed.keyDates = keyDates;
    rufeed.externalLinks = externalLinks;
    rufeed.documentTypes = documentTypes;
    rufeed.scaleOfImpact = update_json_feed.scaleOfImpact;
    rufeed.likelyhoodOfImplementation = update_json_feed.likelyhoodOfImplementation;
    rufeed.ragulatorName = regulatortypeName
    rufeed.issuingBodies = [regulatortypeName];
    rufeed.legislativeInitiatives = [];
    rufeed.is_jwg = 1;
    rufeed.AutoFordardJwGSelectdata = 1;

    let dissmiss_reason_text = "";
    let reasondismissal = 'reason_codes_for_dismissal' in Store.getStoreData('module_config') ? Store.getStoreData('module_config').reason_codes_for_dismissal : [];
    if(stack.isChecking === 1){
        for(let d of reasondismissal){
            if(d.isActive === 1 && d.isDelete === 0 && d.isDefaultcode === 1){
                dissmiss_reason_text = d.reasonName;
            }
        }
    }else if(stack.isChecking === 2){
        dissmiss_reason_text = "N/A"
    }

    let rufeedjson = JSON.stringify(rufeed).replace(/%20/g, " ");
    rufeedjson = rufeedjson.replace(/%26/g, "&");
    rufeedjson = JSON.parse(rufeedjson);

    let postData = {"command": "save_JwGData_Select_delete", "id": id, "data": rufeedjson, dissmiss_reason_text: dissmiss_reason_text};
    console.log("postData===>",postData);
    let api = new APICall();
    api.command(postData, this.processDeleteRUTaskForFeed(id));
}

processDeleteRUTaskForFeed = (feedid) => (result) => {
    this.setState({isLoadedIcon: false, alert_param: null})

    let update_autoforward = []
    for(let val of this.state.autoForwordFeedsAndRU) {
        if(val[2] !== feedid){
            update_autoforward.push(val);
        }
    }
    this.setState({autoForwordFeedsAndRU: update_autoforward});
    let postData = { command: "refreshTasks", role: Store.getStoreData('role')};
    let api = new APICall();
    api.command(postData, this.afterProcessRefreshedTasks);

    // let alert_param = {title: 'Success', message: 'The regulatory change has been dismissed.', ok_text: 'OK', confirm: false,
    //                    alertHandler: this.updateprocessDeleteRUTaskForFeed, stack: {feedid: feedid}}
    // this.setState({alert_param: alert_param})
}

// updateprocessDeleteRUTaskForFeed = (result, stack) => {
//     console.log(result,stack)
//     this.setState({alert_param: null})
//     let feedid = stack.feedid
//     let update_autoforward = []
//     for(let val of this.state.autoForwordFeedsAndRU) {
//         if(val[2] !== feedid){
//             update_autoforward.push(val);
//         }
//     }
//     this.setState({autoForwordFeedsAndRU: update_autoforward, isLoadedIcon: false});
//     let postData = { command: "refreshTasks", role: Store.getStoreData('role')};
//     let api = new APICall();
//     api.command(postData, this.afterProcessRefreshedTasks);
// }

afterProcessRefreshedTasks = (result) => {
    console.log("result==>", result)
    Store.updateStore('newRCtasks', result.result['tasks'].newTasks);
    Store.updateStore('pendingRCtasks', result.result['tasks'].pendingTasks);
    Store.updateStore('saveRCtasks', result.result['tasks'].saveTasks);
    Store.updateStore('shareWithMeRCTasks', result.result['tasks'].shareWithMeTasks);
    Store.updateStore('followupRCtasks', result.result['tasks'].followupTasks);
    Store.updateStore('dismissFeedsAndRUtasks', result.result['tasks'].dismissTasks);
    Store.updateStore('myRCtasks', result.result['tasks'].mytasks);

    IndexLoader.divideAutoForwordFeedsAndRUIndex(result.result['tasks'].autoforwordfeedTasks)
    let regulatorObject = Store.getStoreData('ragulatorsKnowAs');
    CardLoader.divideDashboardCardCoutAll(result.result['tasks'], {}, regulatorObject, Store.getStoreData('defaultTimePeriod'))

    let rcm_index = this.context.rcmIndexSelect;
    if(rcm_index !== null && 'result' in rcm_index && 'tasks' in rcm_index.result){
        rcm_index.result.tasks = result.result['tasks'];
    }
    this.context.rcmIndexSelect =  rcm_index
}

processPendingTaskData = () => {
    let pendingRCtasks = this.state.pendingRCtasks;
    let update_pentasks = [];
    let userrole = Store.getStoreData('role');
    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){
        pendingRCtasks.forEach((value) => {
            if (value[1] !== Store.getStoreData('companyid')) {
                update_pentasks.push(value);
            }
        })
    }else{
        update_pentasks = pendingRCtasks;
    }

    let ret = {data: [], columns: []};
    let j = 0;
    let showPendingToggle = userrole === 'rcm_gatekeeper' ? true : this.state.is_show_pending_toggle;
    let curComID = Store.getStoreData('companyid');
    const has_gc = Store.getStoreData('has_gc');
    ret.columns =[  {Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}},
                    {Header: 'Status', accessor: 'status_value', width: 100,
                    Cell: row => (<div>
                        {
                          (() => {
                            switch(row.original.status_value) {
                              case 'In Progress': return  <div><InProgress><span style={{paddingLeft: "15px"}}></span></InProgress>{row.original.status_value}</div>; break;
                              case 'Overdue': return  <div><Overdue><span style={{paddingLeft: "15px"}}></span></Overdue>{row.original.status_value}</div>; break;
                              default: return  <div><Pending><span style={{paddingLeft: "15px"}}></span></Pending>{row.original.status_value}</div>
                            }
                          })()
                        }
                      </div>), headerStyle: {textAlign: 'left'}},
                    {'Header' : 'Document Name', accessor: 'document_name', Cell: row => (
                        <div>
                            {
                                (() => {
                                    if(row.original.externalLink !== ''){
                                        return(<a target="_blank" href={row.original.externalLink}>{row.original.document_name}</a>)
                                    }else{
                                        return(<div>{row.original.document_name}</div>)
                                    }
                                })()

                            }

                        </div>
                      ), width: 750, headerStyle: {textAlign: 'left'}},
                    {Header: 'Company', accessor: 'company_name', minWidth: 150, headerStyle: {textAlign: 'left'}, show: has_gc ? true : false},
                    {Header: 'Document Types', accessor: 'document_types', minWidth: 100, headerStyle: {textAlign: 'left'}},
                    {Header: 'Regulator Name', accessor: 'regulator_name', minWidth: 100, headerStyle: {textAlign: 'left'}},
                    {Header: 'Issue Date', accessor: 'issue_date', minWidth: 100, headerStyle: {textAlign: 'center'}, style: {textAlign: 'center'}, sortMethod: (a, b) => {
                        if (a === b) {
                            return 0;
                        }
                        let adue = a.split("/");
                        const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                        let  bbdue = b.split("/");
                        const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                        return aReverse > bReverse ? 1 : -1;
                      }},
                    {Header: 'Added Date', accessor: 'added_date', minWidth: 80, headerStyle: {textAlign: 'center'}, style: {textAlign: 'center'}, sortMethod: (a, b) => {
                        if (a === b) {
                            return 0;
                        }
                        let adue = a.split("/");
                        const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                        let  bbdue = b.split("/");
                        const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                        return aReverse > bReverse ? 1 : -1;
                      }, style: {textAlign: 'center'}},
                    {Header: 'Followup Tasks', accessor: 'followup_tasks', minWidth: 50, headerStyle: {textAlign: 'left'}},
                    {'Header' : 'Action',headerStyle: {textAlign: 'center'}, width: 100, Cell: row => (
                        <div>
                            <MRButton onClick={this.showRCMtaskModal(row.original.task_id, 'pending')}>View</MRButton>
                        </div>
                    )}
                 ];
    let gc_assoc = Store.getStoreData('gc_assoc_active_inactive');
    let gc_company = "";
    for(let d of update_pentasks) {
        j++;
        gc_company = parseInt(d[1]) < 100000 ? Store.getStoreData('gc_company')[0].company_name : gc_assoc[(parseInt(d[1])%100000).toString()];
        let exterlink = d[6].length !== 0 ? d[6][0].url : "";

        let dNames = "";
        let i=0;
        for (let doc of d[7]){
            i++;
            if(doc in this.state.doctype){
                dNames += this.state.doctype[doc]
                if(i <= d[7].length - 1) {
                    dNames += ', '
                }
            }
        }

        let  regulatorObject = Store.getStoreData('ragulatorsKnowAs');
        let regulatorNamenew = "";
        if(d[8] in regulatorObject){
            regulatorNamenew = regulatorObject[d[8]];
        }

        let text = '';
        let color = '#ffffff';
        if(d[11] === "Overdue"){
            text = d[11];
            color = '#FF0000';
        }else if(d[11] === "Pending"){
            text = d[11];
            color = '#2E8B57';
        }else if(d[11] === "Completed"){
            text = d[11];
            color = '#2E8B57';
        }else if(d[11] === "In Progress"){
            text = d[11];
        }

        let elem = {};
        if(d[11] !== 'Complete'){
            if(!showPendingToggle){
                if(d[1] === curComID){
                    elem = {'index' : j,
                            'task_id': d[0],
                            'document_name' : d[5].replace(/’/g, "'").replace(/“/g, '"').replace(/”/g, '"'),
                            'externalLink': exterlink,
                            'company_name' : gc_company,
                            'document_types' : dNames,
                            'regulator_name' : regulatorNamenew.replace(/'+/g,"'"),
                            'issue_date' : moment(d[9], 'YYYY/MM/DD').format('DD/MM/YYYY'),
                            'followup_tasks' : d[10].toString(),
                            'status' : {text: text, color: color},
                            'status_value': text,
                            'added_date': moment.unix(d[12]).format('DD/MM/YYYY'),
                            'externalLinks': d[6],
                            'keyDates': d[15]
                        };
                    ret.data.push(elem);
                }
            }else{
                //if(d[1] !== curComID){
                    elem = {'index' : j,
                            'task_id': d[0],
                            'document_name' : d[5].replace(/’/g, "'").replace(/“/g, '"').replace(/”/g, '"'),
                            'externalLink': exterlink,
                            'company_name' : gc_company,
                            'document_types' : dNames,
                            'regulator_name' : regulatorNamenew.replace(/'+/g,"'"),
                            'issue_date' : moment(d[9], 'YYYY/MM/DD').format('DD/MM/YYYY'),
                            'followup_tasks' : d[10].toString(),
                            'status' : {text: text, color: color},
                            'status_value': text,
                            'added_date': moment.unix(d[12]).format('DD/MM/YYYY'),
                            'externalLinks': d[6],
                            'keyDates': d[15]
                        };
                    ret.data.push(elem);
                //}
            }
        }
    }
    ret.data = ret.data.sort((a,b) => {
        if (a.issue_date === b.issue_date) {
            return 0;
        }
        let adue = a.issue_date.split("/");
        const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
        let  bbdue = b.issue_date.split("/");
        const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
        return aReverse < bReverse ? 1 : -1;
    });
    let sarr = [];
    let s = 0;
    ret.data.forEach((item) => {
        s++;
        item.index = s;
        sarr.push(item);
    })
    ret.data = sarr;
    Store.updateStore('tbl_cur_inProRus', ret.data)
    return ret;
}

showRCMtaskModal = (task_id, tableType) => (event) =>{
    event.preventDefault();
    Store.updateStore('current_task_id', task_id);
    let postData = { "command": "gettask", "task_id" : task_id };
    console.log("postData===>",postData);
    let api = new APICall();
    this.setState({isLoadedIcon: true})
    api.command(postData, this.processshowRCMtask(tableType));
}
processshowRCMtask = (tableType) => (result) => {
    console.log('result==>', result)
    let parent_task_id = result.result.task.parenttask.parent_task_id;
    let task = JSON.parse(result.result.task.parenttask.task_json);
    Store.updateStore('this_task_detail', result.result);
    Store.updateStore('parent_task_id', parent_task_id);
    Store.updateStore('table_type', tableType);
    Store.updateStore('childtasks', result.result.task['childtask'].result);
    Store.updateStore('task', task.object_data);
    Store.updateStore('current_task', task);
    if(tableType === 'mySharetask'){
        this.updatemyShareTask();
    }else {
        this.setState({isLoadedIcon: false, modal_view: 'taskModal'})
    }
}

updatemyShareTask(){
    let task = Store.getStoreData('this_task_detail');
    let task_id = task.task.parenttask.id;
    let task_json = JSON.parse(task.task.parenttask.task_json);
    let userID = Store.getStoreData('loggedincontact');
    if(userID in task_json.object_data.shareUserAcknowledge && task_json.object_data.shareUserAcknowledge[userID].cur_view === "Unopened"){
        let val = {date: task_json.object_data.shareUserAcknowledge[userID].date, status: task_json.object_data.shareUserAcknowledge[userID].status, OpenedDate:  Date.now(), cur_view: "Opened",responsedate: Date.now()};
        task_json.object_data.shareUserAcknowledge[userID] = val
        task_json['object_data'].shareUserAcknowledge = task_json.object_data.shareUserAcknowledge;
        task_json['action_data'] = {action: "COMP_RCM_ASSIGN", actiondate: Date.now(), cur_lane: "COMP_RCM_ACTION", shareUserAcknowledge: task_json.object_data.shareUserAcknowledge[userID]};
        let taskjson = JSON.stringify(task_json).replace(/(&rsquo;)/g, "\'");
        taskjson = taskjson.replace(/'+/g,"'");
        taskjson = JSON.parse(taskjson);
        let postData = {command: "update_rcm_task", task_json: taskjson, task_id: task_id };
        const api = new APICall();
        api.command(postData, this.processupdatecurview);
    }else if(userID in task_json.object_data.shareUserInfo && task_json.object_data.shareUserInfo[userID].cur_view === "Unopened"){
        let val = {date: task_json.object_data.shareUserInfo[userID].date, status: task_json.object_data.shareUserInfo[userID].status,OpenedDate:  Date.now(), cur_view: "Opened",responsedate: Date.now()};
        task_json.object_data.shareUserInfo[userID] = val
        task_json['object_data'].shareUserInfo = task_json.object_data.shareUserInfo;
        task_json['action_data'] = {action: "COMP_RCM_ASSIGN", actiondate: Date.now(), cur_lane: "COMP_RCM_ACTION", shareUserInfo: task_json.object_data.shareUserInfo[userID]};
        let taskjson = JSON.stringify(task_json).replace(/(&rsquo;)/g, "\'");
        taskjson = taskjson.replace(/'+/g,"'");
        taskjson = JSON.parse(taskjson);
        let postData = {command: "update_rcm_task", task_json: taskjson, task_id: task_id };
        const api = new APICall();
        api.command(postData, this.processupdatecurview);
    }else{
        this.setState({isLoadedIcon: false, modal_view: 'sharetaskModal'})
    }
}

processupdatecurview = (result) => {
    let task = Store.getStoreData('this_task_detail');
    let task_id = task.task.parenttask.id;
    let postData = { "command": "gettask", "task_id" : task_id };
    const api = new APICall();
    api.command(postData, this.processupadteCurrentData);
}
processupadteCurrentData = (result) => {
    Store.updateStore('this_task_detail', result.result);
    this.setState({isLoadedIcon: false, modal_view: 'sharetaskModal'})
}

processDismissedData = () => {
    var dismisstasks = this.state.dismissFeedsAndRUtasks;
    let userrole = Store.getStoreData('role');
    let update_dismisstasks = [];
    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){
        dismisstasks.forEach((value) => {
            if (value[1] !== Store.getStoreData('companyid')) {
                update_dismisstasks.push(value);
            }
        })
    }else{
        update_dismisstasks = dismisstasks;
    }
    const has_gc = Store.getStoreData('has_gc')
    let ret = {data: [], columns: []};
    let j = 0;
    ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}},
                {'Header' : 'Document Name', accessor: 'document_name', Cell: row => (
                    <div>
                        {
                            (() => {
                                if(row.original.externalLink !== ''){
                                    return(<a target="_blank" href={row.original.externalLink}>{row.original.document_name}</a>)
                                }else{
                                    return(<div>{row.original.document_name}</div>)
                                }
                            })()

                        }
                    </div>
                ), width: 750, headerStyle: {textAlign: 'left'}},
                {Header: 'Company', accessor: 'company_name', minWidth: 150, headerStyle: {textAlign: 'left'}, show: has_gc ? true : false},
                {Header: 'Document Types', accessor: 'document_types', minWidth: 100, headerStyle: {textAlign: 'left'}},
                {Header: 'Regulator Name', accessor: 'regulator_name', minWidth: 100, headerStyle: {textAlign: 'left'}},
                {Header: 'Issue Date', accessor: 'regulator_published_date', minWidth: 100, headerStyle: {textAlign: 'center'}, style: {textAlign: 'center'}, sortMethod: (a, b) => {
                    if (a === b) {
                        return 0;
                    }
                    let adue = a.split("/");
                    const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                    let  bbdue = b.split("/");
                    const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                    return aReverse > bReverse ? 1 : -1;
                  }, style: {textAlign: 'center'}},
                {Header: 'Added Date', accessor: 'added_date', minWidth: 80, headerStyle: {textAlign: 'center'}, style: {textAlign: 'center'}, sortMethod: (a, b) => {
                    if (a === b) {
                        return 0;
                    }
                    let adue = a.split("/");
                    const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                    let  bbdue = b.split("/");
                    const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                    return aReverse > bReverse ? 1 : -1;
                  }, style: {textAlign: 'center'}},
                {Header: 'Dismissed Date', accessor: 'dismiss_date', minWidth: 100, headerStyle: {textAlign: 'left'}, sortMethod: (a, b) => {
                    if (a === b) {
                        return 0;
                    }
                    let adue = a.split("/");
                    const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                    let  bbdue = b.split("/");
                    const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                    return aReverse > bReverse ? 1 : -1;
                }},
                {'Header' : 'Action', headerStyle: {textAlign: 'center'}, width: 120, Cell: row => (
                    <div>
                        {
                        (() => {
                            if(row.original.isAutoForwardDismissData) {
                                return (<MRButton onClick={this.viewDismissAutoForwardFeed(row.original.feed_id)}>View</MRButton>)
                            }else{
                                return (<MRButton onClick={this.viewDismissTask(row.original.task_id)}>View</MRButton>)
                            }
                        })()
                        }
                    </div>
                )}
            ];

    let gc_assoc = Store.getStoreData('gc_assoc_active_inactive');
    let gc_company = "";
    for(let d of update_dismisstasks) {
        j++;
        gc_company = parseInt(d[1]) < 100000 ? Store.getStoreData('gc_company')[0].company_name : gc_assoc[(parseInt(d[1])%100000).toString()];
        let exterlink = d[6].length !== 0 ? d[6][0].url : "";

        let dNames = "";
        let i=0;
        for (let doc of d[7]){
            i++;
            if(doc in this.state.doctype){
                dNames += this.state.doctype[doc]
                if(i <= d[7].length - 1) {
                    dNames += ', '
                }
            }
        }

        let  regulatorObject = Store.getStoreData('ragulatorsKnowAs');
        let regulatorNamenew = "";
        if(d[8] in regulatorObject){
            regulatorNamenew = regulatorObject[d[8]];
        }

        let dismissDate = "";
        if(!d[11]){
            if(d[13] === 1){
                // Manually dismissed JWG task
                const actionDateUnixSeconds = d[14] / 1000
                dismissDate = moment.unix(actionDateUnixSeconds).format('DD/MM/YYYY');
            }else{
                // Dismissed custom RU
                const actionDateUnixSeconds = d[10] / 1000
                dismissDate = moment.unix(actionDateUnixSeconds).format('DD/MM/YYYY');
            }
        }else{
            // const created_at_date_obj = new Date(d[10]);
            // const created_at_day = created_at_date_obj.getDate() < 10 ? "0"+created_at_date_obj.getDate().toString() : created_at_date_obj.getDate();
            // const created_at_month = created_at_date_obj.getMonth()+1 < 10 ? `0${(created_at_date_obj.getMonth() + 1).toString()}` : created_at_date_obj.getMonth()+1;
            // const created_at_year = created_at_date_obj.getFullYear();
            // dismissDate = `${created_at_day}/${created_at_month}/${created_at_year}`;
            dismissDate = moment.unix(d[10]).format('DD/MM/YYYY');
        }

        let added_date = d[12];
        if(!d[11]){
            if(d[15] === 1){
                added_date = d[12];
            }else{
                added_date = d[10];
            }
        }

        let elem = {
            'index' : j,
            'task_id': d[0],
            'feed_id': d[2],
            'externalLink': exterlink,
            'document_name' : d[5].replace(/’/g, "'").replace(/“/g, '"').replace(/”/g, '"'),
            'document_types' : dNames,
            'company_name' : gc_company,
            'regulator_name' : regulatorNamenew.replace(/'+/g,"'"),
            'regulator_published_date' : moment(d[9], 'YYYY/MM/DD').format('DD/MM/YYYY'),
            'dismiss_date' : dismissDate,
            'dismissed_by' : d[18],
            'isAutoForwardDismissData': d[11],
            'added_date': moment.unix(added_date).format('DD/MM/YYYY'),
            'externalLinks': d[6],
            'keyDates': d[16],
            'dismissNote': d[17]
        };
        ret.data.push(elem);
    }
    ret.data = ret.data.sort((a,b) => {
        if (a.dismiss_date === b.dismiss_date) {
            return 0;
        }
        let adue = a.dismiss_date.split("/");
        const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
        let  bbdue = b.dismiss_date.split("/");
        const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
        return aReverse < bReverse ? 1 : -1;
    });
    let sarr = [];
    let s = 0;
    ret.data.forEach((item) => {
        s++;
        item.index = s;
        sarr.push(item);
    })
    ret.data = sarr;
    Store.updateStore('tbl_cur_disRus', ret.data)
    return ret;
}

viewDismissAutoForwardFeed = (id) => (event) => {
    event.preventDefault();
    let postData = { "command" : "getFeedSelectSide" , "feed_id": id};
    console.log("postData===>",postData);
    let api = new APICall();
    this.setState({isLoadedIcon: true})
    api.command(postData, this.processsViewAutoForwardDismissTask);
}
processsViewAutoForwardDismissTask = (result) => {
    console.log('result==>', result)
    Store.updateStore('cur_auto_forward_dismiss_task', result)
    this.setState({ modal_view: 'view_auto_froward_dismissed_task',isLoadedIcon: false})
}

viewDismissTask = (id) => (event) => {
    event.preventDefault();
    let postData = { "command": "gettask", "task_id" : id };
    console.log("postData===>",postData);
    let api = new APICall();
    this.setState({isLoadedIcon: true})
    api.command(postData, this.processsViewDismissTask);
}
processsViewDismissTask = (result) => {
    console.log('result==>', result.result.task)
    if(result.result.task.length !== 0){
        Store.updateStore('cur_dismiss_task', result.result.task.parenttask)
        this.setState({ modal_view: 'view_dismissed_task',isLoadedIcon: false})
    }
}

selectdissmiss_cRU = (event) => {
    let taskid = event.target.value;
    let newRCtasks_value = this.state.newRCtasks;
    let up_custom = [];
    let mutipleDelObject_cRU = this.state.mutipleDelObject_cRU;
    newRCtasks_value.forEach((d) => {
        if(parseInt(d[0]) === parseInt(taskid) && d[14] === false){
            d[14] = true
            mutipleDelObject_cRU[d[0].toString()] = 1
        }else if(parseInt(d[0]) === parseInt(taskid) && d[14] === true){
            d[14] = false
            delete mutipleDelObject_cRU[d[0].toString()];
        }
        up_custom.push(d)
    })
    console.log("mutipleDelObject_cRU==>", mutipleDelObject_cRU)
    this.setState({newRCtasks: up_custom, rowcheckedMultiDiss_cRU: false, mutipleDelObject_cRU})
    let allselectdissmissRUcheck = true
    for(let d of up_custom) {
        if(!d[14]){
            allselectdissmissRUcheck = false
        }
    }
    if(allselectdissmissRUcheck){
        this.setState({rowcheckedMultiDiss_cRU: true})
    }
}

selectAlldissmiss_cRU = (event) => {
    let rowcheckedMultiDiss_cRU = this.state.rowcheckedMultiDiss_cRU;
    rowcheckedMultiDiss_cRU = rowcheckedMultiDiss_cRU ? false : true;
    let newRCtasks_value = this.state.newRCtasks;
    let mutipleDelObject_cRU = this.state.mutipleDelObject_cRU;
    if(rowcheckedMultiDiss_cRU) {
        let up_custom = [];
        newRCtasks_value.forEach((d) => {
            d[14] = true
            up_custom.push(d)
            mutipleDelObject_cRU[d[0].toString()] = 1
        })
        this.setState({rowcheckedMultiDiss_cRU: rowcheckedMultiDiss_cRU, newRCtasks: up_custom, mutipleDelObject_cRU})
    }else{
        let up_custom = [];
        newRCtasks_value.forEach((d) => {
            d[14] = false
            up_custom.push(d)
            delete mutipleDelObject_cRU[d[0].toString()];
        })
        this.setState({rowcheckedMultiDiss_cRU: rowcheckedMultiDiss_cRU, newRCtasks: up_custom, mutipleDelObject_cRU})
    }
}

processNewTaskData = () => {
    var newtasks = this.state.newRCtasks;
    let userrole = Store.getStoreData('role');
    let update_newtasks = [];
    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){
        newtasks.forEach((value) => {
            if (value[1] !== Store.getStoreData('companyid')) {
                update_newtasks.push(value);
            }
        })
    }else{
        update_newtasks = newtasks;
    }
    const has_gc = Store.getStoreData('has_gc')
    const has_mutidiss = this.state.isShowMultipleDismiss_cRU;
    let ret = {data: [], columns: []};
    let j = 0;
    ret.columns =[
        {'Header' : <div><input type="checkbox" checked={this.state.rowcheckedMultiDiss_cRU} onClick={this.selectAlldissmiss_cRU}/></div>, Cell: row => (
            <div>
                <input type="checkbox" checked={row.original.select} value={row.original.task_id} onClick={this.selectdissmiss_cRU}/>
            </div>
        ), width: 40, filterable: false, sortable: false, headerStyle: {textAlign: 'center'}, style: {textAlign: 'center'}, show: has_mutidiss ? true : false},
        {Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}},
        {'Header' : 'Document Name', accessor: 'document_name', Cell: row => (
            <div>
                {
                    (() => {
                        if(row.original.externalLink !== ''){
                            return(<a target="_blank" href={row.original.externalLink}>{row.original.document_name}</a>)
                        }else{
                            return(<div>{row.original.document_name}</div>)
                        }
                    })()

                }
            </div>
        ), width: 750, headerStyle: {textAlign: 'left'}},
        {Header: 'Company', accessor: 'company_name', minWidth: 150, headerStyle: {textAlign: 'left'}, show: has_gc ? true : false},
        {Header: 'Document Types', accessor: 'document_types', minWidth: 100, headerStyle: {textAlign: 'left'}},
        {Header: 'Regulator Name', accessor: 'regulator_name', minWidth: 100, headerStyle: {textAlign: 'left'}},
        {Header: 'Issue Date', accessor: 'issue_date', minWidth: 80, headerStyle: {textAlign: 'center'}, style: {textAlign: 'center'}, sortMethod: (a, b) => {
            if (a === b) {
                return 0;
            }
            let adue = a.split("/");
            const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
            let  bbdue = b.split("/");
            const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
            return aReverse > bReverse ? 1 : -1;
        }},
        {Header: 'Added Date', accessor: 'added_date', minWidth: 80, headerStyle: {textAlign: 'center'}, style: {textAlign: 'center'}, sortMethod: (a, b) => {
            if (a === b) {
                return 0;
            }
            let adue = a.split("/");
            const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
            let  bbdue = b.split("/");
            const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
            return aReverse > bReverse ? 1 : -1;
          }, style: {textAlign: 'center'}},
        {'Header' : 'Action', headerStyle: {textAlign: 'center'}, width: 220, Cell: row => (
            <div>
                <MRButtonDel onClick={this.addResonforDismiss(row.original.task_id, 'newTask')}>Dismiss</MRButtonDel>
                <MRButton onClick={this.showRCMtaskModal(row.original.task_id, 'new')}>Process</MRButton>
            </div>
        )}
    ];

    let gc_assoc = Store.getStoreData('gc_assoc_active_inactive');
    let gc_company = "";
    for(let d of update_newtasks) {
        j++;
        gc_company = parseInt(d[1]) < 100000 ? Store.getStoreData('gc_company')[0].company_name : gc_assoc[(parseInt(d[1])%100000).toString()];
        let exterlink = d[6].length !== 0 ? d[6][0].url : "";

        let dNames = "";
        let i=0;
        for (let doc of d[7]){
            i++;
            if(doc in this.state.doctype){
                dNames += this.state.doctype[doc]
                if(i <= d[7].length - 1) {
                    dNames += ', '
                }
            }
        }

        let  regulatorObject = Store.getStoreData('ragulatorsKnowAs');
        let regulatorNamenew = "";
        if(d[8] in regulatorObject){
            regulatorNamenew = regulatorObject[d[8]];
        }

        let elem = {
            'index' : j,
            'task_id': d[0],
            'externalLink': exterlink,
            'document_name' : d[5].replace(/’/g, "'").replace(/“/g, '"').replace(/”/g, '"'),
            'document_types' : dNames,
            'company_name' : gc_company,
            'regulator_name' : regulatorNamenew.replace(/'+/g,"'"),
            'issue_date' : moment(d[9], 'YYYY/MM/DD').format('DD/MM/YYYY'),
            'added_date': moment.unix(d[10]).format('DD/MM/YYYY'),
            'externalLinks': d[6],
            'keyDates': d[13],
            'select': d[14]
        };
        ret.data.push(elem);
    }
    ret.data = ret.data.sort((a,b) => {
        if (a.issue_date === b.issue_date) {
            return 0;
        }
        let adue = a.issue_date.split("/");
        const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
        let  bbdue = b.issue_date.split("/");
        const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
        return aReverse < bReverse ? 1 : -1;
    });
    let sarr = [];
    let s = 0;
    ret.data.forEach((item) => {
        s++;
        item.index = s;
        sarr.push(item);
    })
    ret.data = sarr;
    Store.updateStore('tbl_cur_cusRus', ret.data)
    return ret;
}

addResonforDismiss = (taskid, tableType) => (event) => {
    event.preventDefault();
    let alert_param = {title: 'Confirm', message: 'Are you sure you want to dismiss this regulatory change?', ok_text: 'OK', cancel_text: 'Cancel', confirm: true,
                       alertHandler: this.processAddResonforDismiss, stack: {taskid: taskid, tableType: tableType}}
    this.setState({alert_param: alert_param})
}

processAddResonforDismiss = (result, stack) => {
    if(!result) {
        this.setState({alert_param: null})
        return
    }
    this.setState({alert_param: null, isLoadedIcon: true})
    let action_data = {
        task_id: stack.taskid,
        cur_lane: "COMP_RCM_DISMISS",
        assigned_to: 0,
        action: "COMP_RCM_DISMISS",
        note: "",
        performed_by: Store.getStoreData('loggedincontact')
    };
    let postData = {"command": "addactiontotask", "SystemID": 1001, action_data: action_data, task_id: stack.taskid};
    console.log("postData==>", postData)
    this.setState({isLoadedIcon: true})
    let api = new APICall();
    api.command(postData, this.processDeleteRUTask(stack.taskid, stack.tableType));
}

processDeleteRUTask = (taskid, tableType) => (result) => {
    this.setState({alert_param: null})
    //alert("This Regulatory Update has been dismissed");
    let alert_param = {title: 'Success', message: 'The regulatory change has been dismissed.', ok_text: 'OK', confirm: false,
                       alertHandler: this.updateProcessDeleteRUTask, stack: {taskid: taskid, tableType: tableType}}
    this.setState({alert_param: alert_param})
}

updateProcessDeleteRUTask = (result, stack) => {
    console.log(result,stack)
    this.setState({alert_param: null})
    let tableType = stack.tableType;
    let taskid = stack.taskid;
    if(tableType === 'newTask') {
        let update_new_task = []
        for(let val of this.state.newRCtasks) {
            if(val[0] !== taskid){
                update_new_task.push(val);
            }
        }
        this.setState({newRCtasks: update_new_task, isLoadedIcon: false});
    }else{
        let update_autoforward = []
        for(let val of this.state.autoForwordFeedsAndRU) {
            if(val[0] !== taskid){
                update_autoforward.push(val);
            }
        }
        this.setState({autoForwordFeedsAndRU: update_autoforward, isLoadedIcon: false});
    }
    let postData = { command: "refreshTasks", role: Store.getStoreData('role')};
    let api = new APICall();
    api.command(postData, this.afterProcessRefreshedTasks);
}

dismissMultiple_cRU = (event) => {
    event.preventDefault();
    let alert_param = {title: 'Confirm', message: 'Are you sure you want to dismiss these regulatory changes? You can re-process them later.', ok_text: 'Yes', cancel_text: 'No', confirm: true,
                       alertHandler: this.mutipleDismiss_cRU, stack: {}}
    this.setState({alert_param: alert_param})
}

mutipleDismiss_cRU = (result, stack) => {
    if(!result) {
        this.setState({alert_param: null})
        return
    }
    this.setState({alert_param: null})
    // let multidismissArray = [];
    // for(let k in this.state.mutipleDelObject_cRU){
    //     let conv = parseInt(k)
    //     multidismissArray.push(conv);
    // }

    let dissmiss_reason_text = "N/A";
    if(Store.getStoreData('module_config').general.require_reason_code_for_dismissal){
        let reasonCodesDismissal = 'reason_codes_for_dismissal' in Store.getStoreData('module_config') ? Store.getStoreData('module_config').reason_codes_for_dismissal : [];
        for(let d of reasonCodesDismissal){
            if('isDefMultiDis' in d && d.isDefMultiDis === 1){
                dissmiss_reason_text = d.reasonName;
            }
        }
    }

    let postData = {"command": "startbg_multiple_dismissal_customRUs", mutipleDelObject: this.state.mutipleDelObject_cRU,
    dissmiss_reason_text: dissmiss_reason_text};
    console.log("postData===>",postData);
    let api = new APICall();
    this.setState({isLoadedIcon: true})
    api.command(postData, this.processmutipleDismisscRU);
}

processmutipleDismisscRU = (result) => {
    console.log('result===>', result)
    setTimeout(() => {
        this.trackShareProgress_cRU()
    }, 2000);
    this.setState({show_progress_cRU: true, isLoadedIcon: false, dismissFeed_count_cRU: 0})
}
trackShareProgress_cRU = () => {
    let postData = {command: "get_dismiss_count_cRU", mutipleDelObjectIDs: this.state.mutipleDelObject_cRU};
    const api = new APICall();
    Store.updateStore('hide_progress', true)
    api.command(postData, this.processTrackProgress_cRU);
}

processTrackProgress_cRU = (result) => {
    console.log('result trackprogress', result)
    let count = result.result.count
    Store.updateStore('hide_progress', false)
    console.log("count===>", count)
    console.log("Object.keys(this.state.mutipleDelObject).length===>", Object.keys(this.state.mutipleDelObject_cRU).length)
    console.log("this.stop_polling===>", this.stop_polling)
    if(count < Object.keys(this.state.mutipleDelObject_cRU).length - 1 && !this.stop_polling) {
        setTimeout(() => {
            this.trackShareProgress_cRU()
        }, 2000);
        this.setState({dismissFeed_count_cRU:count})
    } else {
        this.setState({dismissFeed_count_cRU:count})

        let update_new_task = []
        for(let val of this.state.newRCtasks) {
            if(!(val[0] in this.state.mutipleDelObject_cRU)){
                update_new_task.push(val);
            }
        }
        this.setState({newRCtasks: update_new_task, show_progress_cRU: false, isShowMultipleDismiss_cRU: false, rowcheckedMultiDiss_cRU: false, mutipleDelObject_cRU: {}});
        let postData = { command: "refreshTasks", role: Store.getStoreData('role')};
        let api = new APICall();
        api.command(postData, this.afterProcessRefreshedTasks);
    }
}

processMysaveRUTaskData = () => {
    var mysavetask = this.state.saveRCtasks;
    let userrole = Store.getStoreData('role');
    let update_mysavetask = []
    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){
        mysavetask.forEach((value) => {
            if (value[1] !== Store.getStoreData('companyid')) {
                update_mysavetask.push(value);
            }
        })
    }else{
        update_mysavetask = mysavetask;
    }
    const has_gc = Store.getStoreData('has_gc')
    let ret = {data: [], columns: []};
    let j = 0;
    ret.columns =[  {Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}},
                    {Header: 'Status', accessor: 'status_value', width: 100,
                    Cell: row => (<div>
                        {
                          (() => {
                            switch(row.original.status_value) {
                              case 'In Progress': return  <div><InProgress><span style={{paddingLeft: "15px"}}></span></InProgress>{row.original.status_value}</div>; break;
                              case 'Pending': return  <div><Pending><span style={{paddingLeft: "15px"}}></span></Pending>{row.original.status_value}</div>; break;
                              default: return  <div><Saved><span style={{paddingLeft: "15px"}}></span></Saved>{row.original.status_value}</div>
                            }
                          })()
                        }
                      </div>), headerStyle: {textAlign: 'left'}},
                    {'Header' : 'Document Name', accessor: 'document_name', Cell: row => (
                        <div>
                            {
                                (() => {
                                    if(row.original.externalLink !== ''){
                                        return(<a target="_blank" href={row.original.externalLink}>{row.original.document_name}</a>)
                                    }else{
                                        return(<div>{row.original.document_name}</div>)
                                    }
                                })()

                            }

                        </div>
                      ), width: 750, headerStyle: {textAlign: 'left'}},
                    {Header: 'Company', accessor: 'company_name', minWidth: 150, headerStyle: {textAlign: 'left'}, show: has_gc ? true : false},
                    {Header: 'Document Types', accessor: 'document_types', minWidth: 100, headerStyle: {textAlign: 'left'}},
                    {Header: 'Regulator Name', accessor: 'regulator_name', minWidth: 100, headerStyle: {textAlign: 'left'}},
                    {Header: 'Last Saved Date', accessor: 'last_save_date', minWidth: 100, headerStyle: {textAlign: 'left'}, sortMethod: (a, b) => {
                        if (a === b) {
                            return 0;
                        }
                        let adue = a.split("/");
                        const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                        let  bbdue = b.split("/");
                        const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                        return aReverse > bReverse ? 1 : -1;
                      }},
                    {Header: 'Added Date', accessor: 'added_date', minWidth: 80, headerStyle: {textAlign: 'center'}, style: {textAlign: 'center'}, sortMethod: (a, b) => {
                        if (a === b) {
                            return 0;
                        }
                        let adue = a.split("/");
                        const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                        let  bbdue = b.split("/");
                        const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                        return aReverse > bReverse ? 1 : -1;
                      }, style: {textAlign: 'center'}},
                    {'Header' : 'Action',headerStyle: {textAlign: 'left'}, width: 140, Cell: row => (
                        <div>
                            {
                            (() => {
                                // if(row.original.status_value === 'In Progress') {
                                if(row.original.status_value === 'Pending') {
                                    if(row.original.israwfeed === 1){
                                        return (<div style={{display: "inline-flex"}}>
                                        <MRButton style={{cursor: 'pointer'}} onClick={this.viewFeedTask(row.original.feed_id)}>Process</MRButton>
                                        </div>)
                                    }else{
                                        return (<div style={{display: "inline-flex"}}>
                                        <MRButton style={{cursor: 'pointer'}} onClick={this.viewSaveFeedRU(row.original.id)}>Process</MRButton>
                                        </div>)
                                    }
                                }else{
                                    return (<div style={{display: "inline-flex"}}>
                                        <MRButton style={{cursor: 'pointer'}} onClick={this.viewsaveTask(row.original.id)}>View</MRButton>
                                        <div style={{fontSize: "20px",marginLeft: "15px",cursor: 'pointer', color: "#282828", fontWeight: "bold"}} onClick={this.deleteSaveRUTask(row.original.id)}><AiFillDelete /></div>
                                </div>)
                                }
                            })()
                            }
                        </div>
                        )}
                 ];
    let gc_assoc = Store.getStoreData('gc_assoc_active_inactive');
    let gc_company = "";
    for(let d of update_mysavetask) {
        j++;
        gc_company = parseInt(d[1]) < 100000 ? Store.getStoreData('gc_company')[0].company_name : gc_assoc[(parseInt(d[1])%100000).toString()];
        let exterlink = d[6].length !== 0 ? d[6][0].url : "";

        let dNames = "";
        let i=0;
        for (let doc of d[7]){
            i++;
            if(doc in this.state.doctype){
                dNames += this.state.doctype[doc]
                if(i <= d[7].length - 1) {
                    dNames += ', '
                }
            }
        }
        let  regulatorObject = Store.getStoreData('ragulatorsKnowAs');
        let regulatorNamenew = "";
        if(d[8] in regulatorObject){
            regulatorNamenew = regulatorObject[d[8]];
        }

        let elem = {'index' : j,
                'id': d[0],
                'feed_id': d[2],
                'company_name' : gc_company,
                'externalLink': exterlink,
                'document_name' : d[5].replace(/’/g, "'").replace(/“/g, '"').replace(/”/g, '"'),
                'document_types' : dNames,
                'regulator_name' : regulatorNamenew.replace(/'+/g,"'"),
                'status_value': d[11],
                'last_save_date': moment.unix(d[10]).format('DD/MM/YYYY'),
                'israwfeed': d[12],
                'added_date': moment.unix(d[9]).format('DD/MM/YYYY'),
                'externalLinks': d[6],
                'keyDates': d[13]
               };
        ret.data.push(elem);
    }
    ret.data = ret.data.sort((a,b) => {
        if (a.last_save_date === b.last_save_date) {
            return 0;
        }
        let adue = a.last_save_date.split("/");
        const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
        let  bbdue = b.last_save_date.split("/");
        const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
        return aReverse < bReverse ? 1 : -1;
    });
    let sarr = [];
    let s = 0;
    ret.data.forEach((item) => {
        s++;
        item.index = s;
        sarr.push(item);
    })
    ret.data = sarr;
    Store.updateStore('tbl_cur_savedRus', ret.data)
    return ret;
}

deleteSaveRUTask = (taskid) => (event) => {
    event.preventDefault();
    let alert_param = {title: 'Confirm', message: 'This will permanently delete the item. Are you sure?', ok_text: 'Yes', cancel_text: 'No', confirm: true,
                       alertHandler: this.deleteRCMTask, stack: {taskid: taskid}}
    this.setState({alert_param: alert_param})
}

deleteRCMTask = (result, stack) => {
    if(!result) {
        this.setState({alert_param: null})
        return
    }
    this.setState({isLoadedIcon: true})
    console.log('ROWS', stack.taskid);
    let postData = {command: "remove_save_task", task_id: stack.taskid };
    console.log("postData",postData)
    let api = new APICall();
    api.command(postData, this.processRemoveRCMData(stack.taskid));
}

processRemoveRCMData = (taskid) => (result) => {
    this.setState({alert_param: null})
    let update_save_task = []
    for(let val of this.state.saveRCtasks) {
        if(val[0] !== taskid){
            update_save_task.push(val);
        }
    }
    let alert_param = {title: 'Success', message: 'The saved RU has been successfully deleted.', ok_text: 'OK', confirm: false,
                       alertHandler: this.updateProcessRemoveRCMData, stack: {}}
    this.setState({saveRCtasks: update_save_task, isLoadedIcon: false, alert_param: alert_param});
}

updateProcessRemoveRCMData = async (result, stack) => {
    this.setState({alert_param: null})
    let postData = { command: "index" };
    let api = new APICall();

    api.command(postData, this.processIndexData)
}
processIndexData = (result) => {
    console.log(result)
    this.context.rcmIndexSelect = result;
    Store.updateStore('newRCtasks', result.result['tasks'].newTasks);
    Store.updateStore('autoForwordFeedsAndRU', result.result['tasks'].autoforwordfeedTasks);
    Store.updateStore('pendingRCtasks', result.result['tasks'].pendingTasks);
    Store.updateStore('saveRCtasks', result.result['tasks'].saveTasks);
    Store.updateStore('shareWithMeRCTasks', result.result['tasks'].shareWithMeTasks);
    Store.updateStore('followupRCtasks', result.result['tasks'].followupTasks);
    Store.updateStore('dismissFeedsAndRUtasks', result.result['tasks'].dismissTasks);
    Store.updateStore('myRCtasks', result.result['tasks'].mytasks);
}

viewsaveTask = (id) => (event) => {
    event.preventDefault();
    let postData = { "command": "gettask", "task_id" : id };
    console.log("postData===>",postData);
    let api = new APICall();
    this.setState({isLoadedIcon: true})
    api.command(postData, this.processsViewsaveTask);
}
processsViewsaveTask = (result) => {
    console.log('result==>', result.result.task)
    if(result.result.task.length !== 0){
        Store.updateStore('cur_mySave_task', result.result.task.parenttask)
        this.setState({ modal_view: 'view_mysave_task', isLoadedIcon: false})
    }
}

viewSaveFeedRU = (task_id) => (event) => {
    event.preventDefault();
    console.log("sourav==>", task_id)
    Store.updateStore('current_task_id', task_id);
    let postData = { "command": "gettask", "task_id" : task_id };
    console.log("postData===>",postData);
    let api = new APICall();
    this.setState({isLoadedIcon: true})
    api.command(postData, this.processsSaveRCMtask);
}

processsSaveRCMtask = (result) => {
    console.log('result==>', result)
    this.setState({isLoadedIcon: false})
    let parent_task_id = result.result.task.parenttask.parent_task_id;
    let task = JSON.parse(result.result.task.parenttask.task_json);
    Store.updateStore('this_task_detail', result.result);
    Store.updateStore('parent_task_id', parent_task_id);
    Store.updateStore('childtasks', result.result.task['childtask'].result);
    Store.updateStore('task', task.object_data);
    Store.updateStore('current_task', task);
    this.setState({ modal_view: 'saveAutoFrowardtaskModal', isLoadedIcon: false})
}

processShareWithMeTask = () => {
    var sharetasks = this.state.shareWithMeRCTasks;
    let userrole = Store.getStoreData('role');
    let update_sharetasks = [];
    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){
        sharetasks.forEach((value) => {
            if (value[1] !== Store.getStoreData('companyid')) {
                update_sharetasks.push(value);
            }
        })
    }else{
        update_sharetasks = sharetasks;
    }
    const has_gc = Store.getStoreData('has_gc')
    let ret = {data: [], columns: []};
    let j = 0;
    ret.columns =[{Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}},
                {'Header' : 'Document Name', accessor: 'document_name', Cell: row => (
                    <div>
                        {
                            (() => {
                                if(row.original.externalLink !== ''){
                                    return(<a target="_blank" href={row.original.externalLink}>{row.original.document_name}</a>)
                                }else{
                                    return(<div>{row.original.document_name}</div>)
                                }
                            })()

                        }
                    </div>
                ), width: 750, headerStyle: {textAlign: 'left'}},
                {Header: 'Company', accessor: 'company_name', minWidth: 150, headerStyle: {textAlign: 'left'}, show: has_gc ? true : false},
                {Header: 'Document Types', accessor: 'document_types', minWidth: 100, headerStyle: {textAlign: 'left'}},
                {Header: 'Regulator Name', accessor: 'regulator_name', minWidth: 100, headerStyle: {textAlign: 'left'}},
                {Header: 'Issue Date', accessor: 'issue_date', minWidth: 100, headerStyle: {textAlign: 'left'}, sortMethod: (a, b) => {
                    if (a === b) {
                        return 0;
                    }
                    let adue = a.split("/");
                    const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                    let  bbdue = b.split("/");
                    const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                    return aReverse > bReverse ? 1 : -1;
                }},
                {Header: 'Assigned Date', accessor: 'assigned_date', minWidth: 80, headerStyle: {textAlign: 'center'}, sortMethod: (a, b) => {
                    if (a === b) {
                        return 0;
                    }
                    let adue = a.split("/");
                    const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                    let  bbdue = b.split("/");
                    const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                    return aReverse > bReverse ? 1 : -1;
                }, style: {textAlign: 'center'}},
                {Header: 'Added Date', accessor: 'added_date', minWidth: 80, headerStyle: {textAlign: 'center'}, sortMethod: (a, b) => {
                    if (a === b) {
                        return 0;
                    }
                    let adue = a.split("/");
                    const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                    let  bbdue = b.split("/");
                    const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                    return aReverse > bReverse ? 1 : -1;
                }, style: {textAlign: 'center'}},
                {'Header' : 'Action', headerStyle: {textAlign: 'center'}, width: 120, Cell: row => (
                    <div>
                        <MRButton onClick={this.showRCMtaskModal(row.original.task_id, 'mySharetask')}>Process</MRButton>
                    </div>
                )}
                ];

    let gc_assoc = Store.getStoreData('gc_assoc_active_inactive');
    let gc_company = "";
    for(let d of update_sharetasks) {
        j++;
        gc_company = parseInt(d[1]) < 100000 ? Store.getStoreData('gc_company')[0].company_name : gc_assoc[(parseInt(d[1])%100000).toString()];
        let exterlink = d[6].length !== 0 ? d[6][0].url : "";

        let dNames = "";
        let i=0;
        for (let doc of d[7]){
            i++;
            if(doc in this.state.doctype){
                dNames += this.state.doctype[doc]
                if(i <= d[7].length - 1) {
                    dNames += ', '
                }
            }
        }

        let  regulatorObject = Store.getStoreData('ragulatorsKnowAs');
        let regulatorNamenew = "";
        if(d[8] in regulatorObject){
            regulatorNamenew = regulatorObject[d[8]];
        }

        let elem = {
            'index' : j,
            'task_id': d[0],
            'externalLink': exterlink,
            'document_name' : d[5].replace(/’/g, "'").replace(/“/g, '"').replace(/”/g, '"'),
            'document_types' : dNames,
            'company_name' : gc_company,
            'regulator_name' : regulatorNamenew.replace(/'+/g,"'"),
            'issue_date' : moment(d[9], 'YYYY/MM/DD').format('DD/MM/YYYY'),
            'assigned_date': moment.unix(d[10]).format('DD/MM/YYYY'),
            'added_date': moment.unix(d[11]).format('DD/MM/YYYY'),
            'externalLinks': d[6],
            'keyDates': d[12]
        };
        ret.data.push(elem);
    }
    ret.data = ret.data.sort((a,b) => {
        if (a.assigned_date === b.assigned_date) { //issue_date change to assigned_date
            return 0;
        }
        let adue = a.assigned_date.split("/");
        const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
        let  bbdue = b.assigned_date.split("/");
        const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
        return aReverse < bReverse ? 1 : -1;
    });
    let sarr = [];
    let s = 0;
    ret.data.forEach((item) => {
        s++;
        item.index = s;
        sarr.push(item);
    })
    ret.data = sarr;
    Store.updateStore('tbl_cur_myRus', ret.data)
    return ret;
}

processFollowupTaskData = () => {
    var followuptask = this.state.followupRCtasks;
    let userrole = Store.getStoreData('role');
    let update_followuptask = []
    if(userrole === 'rcm_gatekeeper' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_regulatory_updates' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_regulatory_updates === false){
        followuptask.forEach((value) => {
            if (value[1] !== Store.getStoreData('companyid')) {
                update_followuptask.push(value);
            }
        })
    }else{
        update_followuptask = followuptask;
    }
    console.log("update_followuptask==>", update_followuptask)
    let ret = {data: [], columns: []};
    let j = 0;
    ret.columns =[  {Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}},
                    {Header: 'Status', accessor: 'status_value', width: 100,
                    // Cell: cellInfo => (<div><MRButton style={{cursor: 'default',backgroundColor: `${cellInfo.value.color}`}}>{cellInfo.value.text}</MRButton></div>)},
                    Cell: row => (<div>
                        {
                          (() => {
                            switch(row.original.status_value) {
                              case 'Overdue': return  <div><Overdue><span style={{paddingLeft: "15px"}}></span></Overdue>{row.original.status_value}</div>; break;
                              case 'Completed': return  <div><Complete><span style={{paddingLeft: "15px"}}></span></Complete>{row.original.status_value}</div>; break;
                              default: return  <div><Pending><span style={{paddingLeft: "15px"}}></span></Pending>{row.original.status_value}</div>
                            }
                          })()
                        }
                      </div>), headerStyle: {textAlign: 'left'}},
                    {Header: 'Task', accessor: 'task', width: 750, headerStyle: {textAlign: 'left'}},
                    {Header: 'Regulator Name', accessor: 'regulatory_change', minWidth: 100, headerStyle: {textAlign: 'left'}},
                    {Header: 'Assigned To', accessor: 'assigned_to', minWidth: 100, headerStyle: {textAlign: 'left'}},
                    {Header: 'Assigned Date', accessor: 'assigned_date', minWidth: 100, headerStyle: {textAlign: 'left'}, style: {textAlign: 'center'}, sortMethod: (a, b) => {
                        if (a === b) {
                            return 0;
                        }
                        let adue = a.split("/");
                        const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                        let  bbdue = b.split("/");
                        const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                        return aReverse > bReverse ? 1 : -1;
                      }},
                    {Header: 'Due Date', accessor: 'assigned_duedate', minWidth: 100, headerStyle: {textAlign: 'left'}, sortMethod: (a, b) => {
                        if (a === b) {
                            return 0;
                        }
                        let adue = a.split("/");
                        const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                        let  bbdue = b.split("/");
                        const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                        return aReverse > bReverse ? 1 : -1;
                      }},
                    {Header: 'Issue Date', accessor: 'issue_date', minWidth: 80, headerStyle: {textAlign: 'center'}, style: {textAlign: 'center'}, sortMethod: (a, b) => {
                        if (a === b) {
                            return 0;
                        }
                        let adue = a.split("/");
                        const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                        let  bbdue = b.split("/");
                        const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                        return aReverse > bReverse ? 1 : -1;
                      }, style: {textAlign: 'center'}},
                    {Header: 'Added Date', accessor: 'added_date', minWidth: 80, headerStyle: {textAlign: 'center'}, style: {textAlign: 'center'}, sortMethod: (a, b) => {
                        if (a === b) {
                            return 0;
                        }
                        let adue = a.split("/");
                        const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                        let  bbdue = b.split("/");
                        const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                        return aReverse > bReverse ? 1 : -1;
                      }, style: {textAlign: 'center'}},
                    {'Header' : 'Action',Cell: row => (
                        <div style={{display: "inline-flex"}}>
                            <MRButton onClick={this.viewAssignTask(row.original.id)}>View</MRButton>
                            {
                                (() => {
                                    if(userrole !== 'team'){
                                        if(row.original.status_value !== "Completed")return(<div style={{fontSize: "20px",marginLeft: "15px",cursor: 'pointer', color: "#282828", fontWeight: "bold"}} onClick={this.editFollowupTask(row.original.id)}><BiEdit /></div>)
                                    }
                                })()
                            }
                        </div>
                     ), width: 140, headerStyle: {textAlign: 'left'}}
                 ];
    for(let d of update_followuptask) {
        j++;
        let  regulatorObject = Store.getStoreData('ragulatorsKnowAs');
        let regulatorNamenew = "";
        if(d[6] in regulatorObject){
            regulatorNamenew = regulatorObject[d[6]];
        }

        let text = "";
        let color = "";
        if(d[9] === "Pending"){
            text = d[9];
            color = '#2E8B57'// : '#FF0000';
        }else if(d[9] === "Overdue") {
            text = d[9];
            color = '#FF0000';
        }else if(d[9] === "Completed"){
            text = d[9];
            color = '#2E8B57';
        }

        let elem = {'index' : j,
                'id': d[0],
                'task' : d[5].replace(/’/g, "'").replace(/“/g, '"').replace(/”/g, '"'),
                'regulatory_change' : regulatorNamenew.replace(/'+/g,"'"),
                'assigned_to' : Store.getStoreData('contact'),
                // 'assigned_date': d[7],
                'assigned_date': moment.unix(d[10]).format('DD/MM/YYYY'),
                'assigned_duedate': d[8],
                'status':  {text: text, color: color},
                'status_value': text,
                'issue_date': moment(d[11], 'YYYY/MM/DD').format('DD/MM/YYYY'),
                'added_date': moment.unix(d[12]).format('DD/MM/YYYY')
               };
        ret.data.push(elem);
    }
    ret.data = ret.data.sort((a,b) => {
        if (a.assigned_duedate === b.assigned_duedate) {
            return 0;
        }
        let adue = a.assigned_duedate.split("/");
        const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
        let  bbdue = b.assigned_duedate.split("/");
        const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
        return aReverse < bReverse ? 1 : -1;
    });
    let sarr = [];
    let s = 0;
    ret.data.forEach((item) => {
        s++;
        item.index = s;
        sarr.push(item);
    })
    ret.data = sarr;
    Store.updateStore('tbl_cur_myTasks', ret.data)
    return ret;
}

editFollowupTask = (followupid) => (event) => {
    event.preventDefault();
    let postData = { command: "get_subtask", task_id: followupid};
    let api = new APICall();
    this.setState({isLoadedIcon: true})
    api.command(postData, this.editFollowupModal);
}
editFollowupModal = (result) => {
    console.log("followup_task",result.result.task);
    Store.updateStore('cur_followup_task', result.result.task.subtask)
    Store.updateStore('cur_parentTask', result.result.task.parenttask)
    this.setState({ modal_view: 'edit_followup_task', isLoadedIcon: false})
}

viewAssignTask = (followupid) => (event) => {
    event.preventDefault();
    let postData = { command: "get_subtask", task_id: followupid};
    let api = new APICall();
    this.setState({isLoadedIcon: true})
    api.command(postData, this.openFollowupModal);
}
openFollowupModal = (result) => {
    console.log("followup_task",result.result.task);
    if(result.result.task.length !== 0){
        let currentfollowupData = result.result.task.subtask;
        let followupid = result.result.task.subtask.id;
        let task_json = JSON.parse(currentfollowupData.task_json);
        if('cur_view' in task_json.object_data && task_json.object_data.cur_view === "Unopened"){
            task_json['object_data'].cur_view = "Opened";
            task_json['object_data'].OpenedDate = Date.now();
            let postData = {command: "update_rcm_followup_task", task_json: task_json, task_id: followupid, bin_files: [] };
            console.log(postData);
            const api = new APICall();
            api.command(postData, this.processupdatecurviewFoll(followupid));
        }else{
            Store.updateStore('cur_followup_task', result.result.task.subtask)
            Store.updateStore('cur_parentTask', result.result.task.parenttask)
            this.setState({ modal_view: 'view_followup_task', isLoadedIcon: false})
        }
    }
}

openFollowupModalNew = (result) => {
    Store.updateStore('cur_followup_task', result.result.task.subtask)
    Store.updateStore('cur_parentTask', result.result.task.parenttask)
    this.setState({ modal_view: 'view_followup_task',isLoadedIcon: false})
}
processupdatecurviewFoll = (followupid) => (result) => {
    let postData = { command: "get_subtask", task_id: followupid};
    let api = new APICall();
    api.command(postData, this.openFollowupModalNew);
}

switchRadio = (event) => {
    if (this.state.is_show_pending_toggle === true) {
        this.setState({is_show_pending_toggle: false});
    } else {
        this.setState({is_show_pending_toggle: true});
    }
}

backToDashboard = (event) => {
    this.props.backToDashboard()
}
closeModal = () => {
    this.setState({ modal_view: null });
}
toggleFilter_new_rus = (event) => {
    event.preventDefault();
    let isShowfilter_new_rus = this.state.isShowfilter_new_rus ? false : true;
    this.setState({isShowfilter_new_rus});
}
toggleFilter_inpro_rus = (event) => {
    event.preventDefault();
    let isShowfilter_inpro_rus = this.state.isShowfilter_inpro_rus ? false : true;
    this.setState({isShowfilter_inpro_rus});
}
toggleFilter_dis_rus = (event) => {
    event.preventDefault();
    let isShowfilter_dis_rus = this.state.isShowfilter_dis_rus ? false : true;
    this.setState({isShowfilter_dis_rus});
}
toggleFilter_cus_rus = (event) => {
    event.preventDefault();
    let isShowfilter_cus_rus = this.state.isShowfilter_cus_rus ? false : true;
    this.setState({isShowfilter_cus_rus});
}
toggleFilter_save_rus = (event) => {
    event.preventDefault();
    let isShowfilter_save_rus = this.state.isShowfilter_save_rus ? false : true;
    this.setState({isShowfilter_save_rus});
}
toggleFilter_my_rus = (event) => {
    event.preventDefault();
    let isShowfilter_my_rus = this.state.isShowfilter_my_rus ? false : true;
    this.setState({isShowfilter_my_rus});
}
toggleFilter_my_tasks = (event) => {
    event.preventDefault();
    let isShowfilter_my_tasks = this.state.isShowfilter_my_tasks ? false : true;
    this.setState({isShowfilter_my_tasks});
}

filterSubmitNewRus = (filterNewRusList) => {
    this.setState({autoForwordFeedsAndRU: filterNewRusList})
}
filterSubmitInProRus = (filterNewRusList) => {
    this.setState({pendingRCtasks: filterNewRusList})
}
filterSubmitDisRus = (filterNewRusList) => {
    this.setState({dismissFeedsAndRUtasks: filterNewRusList})
}
filterSubmitCusRus = (filterNewRusList) => {
    this.setState({newRCtasks: filterNewRusList})
}
filterSubmitSaveRus = (filterNewRusList) => {
    this.setState({saveRCtasks: filterNewRusList})
}
filterSubmitMyRus = (filterNewRusList) => {
    this.setState({shareWithMeRCTasks: filterNewRusList})
}
filterSubmitMyTasks = (filterNewRusList) => {
    this.setState({followupRCtasks: filterNewRusList})
}

exportToExcel = (curview) => (event) => {
    event.preventDefault();
    //console.log("curview==>", curview)
    if(curview === 'new_rus'){
        let wb = {SheetNames:[], Sheets:{}};
        let open_tasks = Store.getStoreData('tbl_cur_newRus') === null ? [] : Store.getStoreData('tbl_cur_newRus');
        wb.SheetNames.push("New RUs");
        wb.Sheets["New RUs"] = XLSX.utils.json_to_sheet(XcelLoader.new_rus_export_tasks(open_tasks));
        const format = "DD-MM-YYYY HH:mm:ss"
        let tm = moment().format(format);
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, 'New RUs' + '-' + tm + fileExtension);
    }else if(curview === 'inprogress_rus'){
        let wb = {SheetNames:[], Sheets:{}};
        let open_tasks = Store.getStoreData('tbl_cur_inProRus') === null ? [] : Store.getStoreData('tbl_cur_inProRus');
        wb.SheetNames.push("In Progress RUs");
        wb.Sheets["In Progress RUs"] = XLSX.utils.json_to_sheet(XcelLoader.inpro_rus_export_tasks(open_tasks));
        const format = "DD-MM-YYYY HH:mm:ss"
        let tm = moment().format(format);
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, 'In Progress RUs' + '-' + tm + fileExtension);
    }else if(curview === 'dismissed_rus'){
        let wb = {SheetNames:[], Sheets:{}};
        let open_tasks = Store.getStoreData('tbl_cur_disRus') === null ? [] : Store.getStoreData('tbl_cur_disRus');
        wb.SheetNames.push("Dismissed RUs");
        wb.Sheets["Dismissed RUs"] = XLSX.utils.json_to_sheet(XcelLoader.dismiss_rus_export_tasks(open_tasks));
        const format = "DD-MM-YYYY HH:mm:ss"
        let tm = moment().format(format);
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, 'Dismissed RUs' + '-' + tm + fileExtension);
    }else if(curview === 'custom_rus'){
        let wb = {SheetNames:[], Sheets:{}};
        let open_tasks = Store.getStoreData('tbl_cur_cusRus') === null ? [] : Store.getStoreData('tbl_cur_cusRus');
        wb.SheetNames.push("Custom RUs");
        wb.Sheets["Custom RUs"] = XLSX.utils.json_to_sheet(XcelLoader.custom_rus_export_tasks(open_tasks));
        const format = "DD-MM-YYYY HH:mm:ss"
        let tm = moment().format(format);
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, 'Custom RUs' + '-' + tm + fileExtension);
    }else if(curview === 'saved_rus'){
        let wb = {SheetNames:[], Sheets:{}};
        let open_tasks = Store.getStoreData('tbl_cur_savedRus') === null ? [] : Store.getStoreData('tbl_cur_savedRus');
        wb.SheetNames.push("Saved RUs");
        wb.Sheets["Saved RUs"] = XLSX.utils.json_to_sheet(XcelLoader.saved_rus_export_tasks(open_tasks));
        const format = "DD-MM-YYYY HH:mm:ss"
        let tm = moment().format(format);
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, 'Saved RUs' + '-' + tm + fileExtension);
    }else if(curview === 'my_rus'){
        let wb = {SheetNames:[], Sheets:{}};
        let open_tasks = Store.getStoreData('tbl_cur_myRus') === null ? [] : Store.getStoreData('tbl_cur_myRus');
        wb.SheetNames.push("My RUs");
        wb.Sheets["My RUs"] = XLSX.utils.json_to_sheet(XcelLoader.my_rus_export_tasks(open_tasks));
        const format = "DD-MM-YYYY HH:mm:ss"
        let tm = moment().format(format);
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, 'My RUs' + '-' + tm + fileExtension);
    }else if(curview === 'my_tasks'){
        let wb = {SheetNames:[], Sheets:{}};
        let open_tasks = Store.getStoreData('tbl_cur_myTasks') === null ? [] : Store.getStoreData('tbl_cur_myTasks');
        wb.SheetNames.push("My Tasks");
        wb.Sheets["My Tasks"] = XLSX.utils.json_to_sheet(XcelLoader.my_tasks_export_tasks(open_tasks));
        const format = "DD-MM-YYYY HH:mm:ss"
        let tm = moment().format(format);
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, 'My Tasks' + '-' + tm + fileExtension);
    }
}

showMultipleDismiss = (event) => {
    event.preventDefault();
    this.setState({isShowMultipleDismiss: true});
}
showMultipleDismiss_cRU = (event) => {
    event.preventDefault();
    this.setState({isShowMultipleDismiss_cRU: true});
}

dismissMultipleru = (event) => {
    event.preventDefault();
    let alert_param = {title: 'Confirm', message: 'Are you sure you want to dismiss these regulatory changes? You can re-process them later.', ok_text: 'Yes', cancel_text: 'No', confirm: true,
                       alertHandler: this.mutipleDismissRUFeedTask, stack: {}}
    this.setState({alert_param: alert_param})
    // this.setState({isAfterDismiss: true})
}

mutipleDismissRUFeedTask = (result, stack) => {
    if(!result) {
        this.setState({alert_param: null})
        return
    }
    this.setState({alert_param: null})
    let multidismissArray = [];
    for(let k in this.state.mutipleDelObject){
        multidismissArray.push(k);
    }

    let dissmiss_reason_text = "N/A";
    if(Store.getStoreData('module_config').general.require_reason_code_for_dismissal){
        let reasonCodesDismissal = 'reason_codes_for_dismissal' in Store.getStoreData('module_config') ? Store.getStoreData('module_config').reason_codes_for_dismissal : [];
        for(let d of reasonCodesDismissal){
            if('isDefMultiDis' in d && d.isDefMultiDis === 1){
                dissmiss_reason_text = d.reasonName;
            }
        }
    }

    let postData = {"command": "startbg_multiple_dismissal_JwGData_Select", multidismissArray: multidismissArray, mutipleDelObject: this.state.mutipleDelObject,
    dissmiss_reason_text: dissmiss_reason_text};
    console.log("postData===>",postData);
    let api = new APICall();
    this.setState({isLoadedIcon: true})
    api.command(postData, this.processmutipleDismissRUFeedTask);
}

processmutipleDismissRUFeedTask = (result) => {
    console.log('result===>', result)
    setTimeout(() => {
        this.trackShareProgress()
    }, 2000);
    let update_autoforward = []
    for(let val of this.state.autoForwordFeedsAndRU) {
        if(!(val[2] in this.state.mutipleDelObject)){
            update_autoforward.push(val);
        }
    }
    let update_store = [];
    let autoForwordFeedsAndRU_update = Store.getStoreData('autoForwordFeedsAndRU_update');
    for(let val of autoForwordFeedsAndRU_update) {
        if(!(val[2] in this.state.mutipleDelObject)){
            update_store.push(val);
        }
    }
    Store.updateStore('autoForwordFeedsAndRU_update', update_store)
    Store.updateStore('isdismissrunning', true)
    Store.updateStore('mutipleDelObject', this.state.mutipleDelObject)
    // this.setState({show_progress: true, isLoadedIcon: false, dismissFeed_count: 0})
    this.setState({autoForwordFeedsAndRU: update_autoforward, isShowMultipleDismiss: false, rowcheckedMultiDiss: false, isAfterDismiss: true, isLoadedIcon: false, dismissFeed_count: 0})
}
trackShareProgress = () => {
    let postData = {command: "get_dismiss_count"};
    const api = new APICall();
    Store.updateStore('hide_progress', true)
    api.command(postData, this.processTrackProgress);
}

processTrackProgress = (result) => {
    console.log('result trackprogress', result)
    let allFeedid = result.result;
    let count = 0;
    for(let k in this.state.mutipleDelObject){
        if(k in allFeedid){
            count ++;
        }
    }
    Store.updateStore('hide_progress', false)
    console.log("count===>", count)
    console.log("Object.keys(this.state.mutipleDelObject).length===>", Object.keys(this.state.mutipleDelObject).length)
    console.log("this.stop_polling===>", this.stop_polling)
    if(count < Object.keys(this.state.mutipleDelObject).length - 1 && !this.stop_polling) {
        setTimeout(() => {
            this.trackShareProgress()
        }, 2000);
        this.setState({dismissFeed_count:count})
    } else {
        // this.setState({dismissFeed_count:count})
        // let update_autoforward = []
        // for(let val of this.state.autoForwordFeedsAndRU) {
        //     if(!(val[2] in this.state.mutipleDelObject)){
        //         update_autoforward.push(val);
        //     }
        // }
        // this.setState({autoForwordFeedsAndRU: update_autoforward, show_progress: false, isShowMultipleDismiss: false, rowcheckedMultiDiss: false, mutipleDelObject: {}});
        Store.updateStore('isdismissrunning', false)
        Store.updateStore('mutipleDelObject', null)
        this.setState({dismissFeed_count:count, isAfterDismiss: false, mutipleDelObject: {}});
        let postData = { command: "refreshTasks", role: Store.getStoreData('role')};
        let api = new APICall();
        api.command(postData, this.afterProcessRefreshedTasks);
    }
}

  render() {
    if(!this.state.ready){
        return(<div></div>)
    }
    return (<div>
        {
            (() => {
                if(this.state.isLoadedIcon){
                    return <div><CSLLoader style={{position: 'absolute'}}/></div>
                }
            })()
        }
        <FilterContainer>
            <FilterInnerContainerLeft>
                <div style={{display: "inline-block", width: "10%", float: "left"}}>
                    <Rectangle><div style={{cursor: "pointer",fontSize: "35px",color: "#282828c9"}} onClick={this.backToDashboard}><IoIosArrowBack/></div></Rectangle>
                </div>
                <div style={{display: "inline-block", width: "60%", float: "left"}}>
                    <p style={{ color: "#435a73", fontSize: "22px", fontWeight: 700 }}>{this.props.headerText}</p>
                </div>

            </FilterInnerContainerLeft>
        </FilterContainer>
        {
            (() => {
                if('cur_view' in this.props && this.props.cur_view === 'new_rus'){
                    return(<PendingContainer>
                        <PendingInnerContainer>
                            {
                                (() => {
                                    if(!this.state.isAfterDismiss && Object.keys(this.state.mutipleDelObject).length === 0){
                                        return(<div style={{cursor: "pointer", fontWeight: "600", width: "10%"}} onClick={this.showMultipleDismiss}>Dismiss Multiple</div>)
                                    }else if(!this.state.isAfterDismiss){
                                        return(<div style={{cursor: "pointer", fontWeight: "600", width: "14%"}} onClick={this.dismissMultipleru}>Dismiss Selected ({Object.keys(this.state.mutipleDelObject).length})</div>)
                                    }else if(this.state.isAfterDismiss){
                                        return(<div>
                                                <div style={{display: "inline-block", fontWeight: "600"}}>
                                                    <ClipLoader size={25} color={"#123abc"} loading={true}/>
                                                </div>
                                                <div style={{display: "inline-block", fontWeight: "600", verticalAlign: "top", marginLeft: "10px", marginTop: "7px"}}>
                                                    {this.state.dismissFeed_count} / {Object.keys(this.state.mutipleDelObject).length} RUs are currently being dismissed. Please wait to dismiss further
                                                </div>
                                            </div>)
                                    }
                                })()
                            }

                            <div style={{marginTop: "20px"}}>
                                <EmailTopHeader style={{display: "inline-block" , fontWeight: 700}}>New Regulatory Updates
                                <div style={{float:'right'}}>
                                    {
                                        (() => {
                                            if(!this.state.isShowfilter_new_rus){
                                                return(<div style={{float: "right", cursor: "pointer",fontSize: "20px",color: "#e4e4e4"}} onClick={this.toggleFilter_new_rus}>
                                                            <IoIosArrowDown />
                                                        </div>)
                                            }else{
                                                return(<div style={{float: "right", cursor: "pointer",fontSize: "20px",color: "#e4e4e4"}} onClick={this.toggleFilter_new_rus}>
                                                            <IoIosArrowUp />
                                                        </div>)
                                            }
                                        })()
                                    }

                                </div>
                                </EmailTopHeader>
                                {
                                    (() => {
                                        if(this.state.isShowfilter_new_rus){
                                            return(<AdvancedFilterNewRus newRus={this.state.autoForwordFeedsAndRU} doctype={this.state.doctype} regulatorObject={this.state.regulatorObject} filterSubmitValue={this.filterSubmitNewRus}/>)
                                        }
                                    })()
                                }
                                <CSLTable
                                    processData={this.processautoForwordFeedsData}
                                    tableRows={10}
                                    headerText={''}
                                />
                                <div style={{float: "left"}}>
                                {
                                    (() => {
                                        if(this.state.autoForwordFeedsAndRU.length !== 0){
                                            return(<ExportButton onClick={this.exportToExcel('new_rus')}>Export</ExportButton>)
                                        }
                                    })()
                                }
                                </div>
                                <div style={{clear: "both"}}></div>
                            </div>
                        </PendingInnerContainer>
                        {/* {
                            (() => {
                                if(this.state.show_progress) {
                                    let barwidth = `${parseInt(this.state.dismissFeed_count * 100 / Object.keys(this.state.mutipleDelObject).length)}%`
                                    return (<div><InactiveOverlay /><ProgressContainer>
                                            Dismissing the regulatory change.
                                            <ProgressBarTotal>
                                                <ProgressBar style={{width: barwidth}} />
                                            </ProgressBarTotal>
                                            <span style={{fontSize: 12}}>{this.state.dismissFeed_count} / {Object.keys(this.state.mutipleDelObject).length} RUs are currently being dismissed. Please wait to dismiss further.</span>
                                        </ProgressContainer>
                                        </div>)
                                }
                            })()
                        }                       */}
                    </PendingContainer>)
                }
            })()
        }
        {
            (() => {
                if('cur_view' in this.props && this.props.cur_view === 'inprogress_rus'){
                    return(<PendingContainer>
                        <PendingInnerContainer>
                            <div style={{marginTop: "20px"}}>
                                <EmailTopHeader style={{display: "inline-block" , fontWeight: 700}}>In Progress Regulatory Updates
                                <div style={{float:'right'}}>
                                    {
                                        (() => {
                                            if(!this.state.isShowfilter_inpro_rus){
                                                return(<div style={{float: "right", cursor: "pointer",fontSize: "20px",color: "#e4e4e4"}} onClick={this.toggleFilter_inpro_rus}>
                                                            <IoIosArrowDown />
                                                        </div>)
                                            }else{
                                                return(<div style={{float: "right", cursor: "pointer",fontSize: "20px",color: "#e4e4e4"}} onClick={this.toggleFilter_inpro_rus}>
                                                            <IoIosArrowUp />
                                                        </div>)
                                            }
                                        })()
                                    }
                                    {
                                        (() => {
                                            if(this.state.showinRole){
                                                return(<div style={{float: "right"}}>
                                                    <div style={{float: "right", marginRight: "5px"}}>
                                                    {
                                                        (() => {
                                                            if (this.state.is_show_pending_toggle === true) {
                                                                return (
                                                                    <div>
                                                                        <SelectedRadio>Yes</SelectedRadio>
                                                                        <UnselectedRadio onClick={this.switchRadio}>No</UnselectedRadio>
                                                                    </div>
                                                                );
                                                            } else {
                                                                return (
                                                                    <div>
                                                                        <UnselectedRadio onClick={this.switchRadio}>Yes</UnselectedRadio>
                                                                        <SelectedRadio>No</SelectedRadio>
                                                                    </div>
                                                                );
                                                            }
                                                        })()
                                                    }
                                                    </div>

                                                    <div style={{float: "left", marginRight: "10px", marginTop: "2px"}}>Show all Group RUs</div>
                                                </div>)
                                            }
                                        })()
                                    }

                                </div>
                                </EmailTopHeader>
                                {
                                    (() => {
                                        if(this.state.isShowfilter_inpro_rus){
                                            return(<AdvancedFilterInProRus newRus={this.state.pendingRCtasks} doctype={this.state.doctype} regulatorObject={this.state.regulatorObject} filterSubmitValue={this.filterSubmitInProRus}/>)
                                        }
                                    })()
                                }
                                <CSLTable
                                    processData={this.processPendingTaskData}
                                    tableRows={10}
                                    headerText={''}
                                />
                                <div style={{float: "left"}}>
                                {
                                    (() => {
                                        if(this.state.pendingRCtasks.length !== 0){
                                            return(<ExportButton onClick={this.exportToExcel('inprogress_rus')}>Export</ExportButton>)
                                        }
                                    })()
                                }
                                </div>
                                <div style={{clear: "both"}}></div>
                            </div>
                        </PendingInnerContainer>
                    </PendingContainer>)
                }
            })()
        }
        {
            (() => {
                if('cur_view' in this.props && this.props.cur_view === 'dismissed_rus'){
                    return(<PendingContainer>
                        <PendingInnerContainer>
                            <div style={{marginTop: "20px"}}>
                                <EmailTopHeader style={{display: "inline-block" , fontWeight: 700}}>Dismissed Regulatory Updates
                                <div style={{float:'right'}}>
                                {
                                    (() => {
                                        if(!this.state.isShowfilter_dis_rus){
                                            return(<div style={{float: "right", cursor: "pointer",fontSize: "20px",color: "#e4e4e4"}} onClick={this.toggleFilter_dis_rus}>
                                                        <IoIosArrowDown />
                                                    </div>)
                                        }else{
                                            return(<div style={{float: "right", cursor: "pointer",fontSize: "20px",color: "#e4e4e4"}} onClick={this.toggleFilter_dis_rus}>
                                                        <IoIosArrowUp />
                                                    </div>)
                                        }
                                    })()
                                }
                                </div>
                                </EmailTopHeader>
                                {
                                    (() => {
                                        if(this.state.isShowfilter_dis_rus){
                                            return(<AdvancedFilterDisRus doctype={this.state.doctype} regulatorObject={this.state.regulatorObject} filterSubmitValue={this.filterSubmitDisRus}/>)
                                        }
                                    })()
                                }
                                <CSLTable
                                    processData={this.processDismissedData}
                                    tableRows={10}
                                    headerText={''}
                                />
                                <div style={{float: "left"}}>
                                {
                                    (() => {
                                        if(this.state.dismissFeedsAndRUtasks.length !== 0){
                                            return(<ExportButton onClick={this.exportToExcel('dismissed_rus')}>Export</ExportButton>)
                                        }
                                    })()
                                }
                                </div>
                                <div style={{clear: "both"}}></div>
                            </div>
                        </PendingInnerContainer>
                    </PendingContainer>)
                }
            })()
        }
        {
            (() => {
                if('cur_view' in this.props && this.props.cur_view === 'custom_rus'){
                    return(<PendingContainer>
                        <PendingInnerContainer>
                            {
                                (() => {
                                    if(Object.keys(this.state.mutipleDelObject_cRU).length === 0){
                                        return(<div style={{cursor: "pointer", fontWeight: "600", width: "10%"}} onClick={this.showMultipleDismiss_cRU}>Dismiss Multiple</div>)
                                    }else{
                                        return(<div style={{cursor: "pointer", fontWeight: "600", width: "14%"}} onClick={this.dismissMultiple_cRU} >Dismiss Selected ({Object.keys(this.state.mutipleDelObject_cRU).length})</div>)
                                    }
                                })()
                            }
                            <div style={{marginTop: "20px"}}>
                                <EmailTopHeader style={{display: "inline-block" , fontWeight: 700}}>Custom Regulatory Updates
                                <div style={{float:'right'}}>
                                    {
                                        (() => {
                                            if(!this.state.isShowfilter_cus_rus){
                                                return(<div style={{float: "right", cursor: "pointer",fontSize: "20px",color: "#e4e4e4"}} onClick={this.toggleFilter_cus_rus}>
                                                            <IoIosArrowDown />
                                                        </div>)
                                            }else{
                                                return(<div style={{float: "right", cursor: "pointer",fontSize: "20px",color: "#e4e4e4"}} onClick={this.toggleFilter_cus_rus}>
                                                            <IoIosArrowUp />
                                                        </div>)
                                            }
                                        })()
                                    }
                                </div>
                                </EmailTopHeader>
                                {
                                    (() => {
                                        if(this.state.isShowfilter_cus_rus){
                                            return(<AdvancedFilterCustomRus doctype={this.state.doctype} regulatorObject={this.state.regulatorObject} filterSubmitValue={this.filterSubmitCusRus}/>)
                                        }
                                    })()
                                }
                                <CSLTable
                                    processData={this.processNewTaskData}
                                    tableRows={10}
                                    headerText={''}
                                />
                                <div style={{float: "left"}}>
                                {
                                    (() => {
                                        if(this.state.newRCtasks.length !== 0){
                                            return(<ExportButton onClick={this.exportToExcel('custom_rus')}>Export</ExportButton>)
                                        }
                                    })()
                                }
                                </div>
                                <div style={{clear: "both"}}></div>
                            </div>
                        </PendingInnerContainer>
                        {
                            (() => {
                                if(this.state.show_progress_cRU) {
                                    let barwidth = `${parseInt(this.state.dismissFeed_count_cRU * 100 / Object.keys(this.state.mutipleDelObject_cRU).length)}%`
                                    return (<div><InactiveOverlay /><ProgressContainer>
                                            Dismissing the regulatory change.
                                            <ProgressBarTotal>
                                                <ProgressBar style={{width: barwidth}} />
                                            </ProgressBarTotal>
                                            <span style={{fontSize: 12}}>{this.state.dismissFeed_count_cRU} / {Object.keys(this.state.mutipleDelObject_cRU).length} RUs are currently being dismissed. Please wait to dismiss further.</span>
                                        </ProgressContainer>
                                        </div>)
                                }
                            })()
                        }
                    </PendingContainer>)
                }
            })()
        }
        {
            (() => {
                if('cur_view' in this.props && this.props.cur_view === 'saved_rus'){
                    return(<PendingContainer>
                        <PendingInnerContainer>
                            <div style={{marginTop: "20px"}}>
                                <EmailTopHeader style={{display: "inline-block" , fontWeight: 700}}>Saved Regulatory Updates
                                <div style={{float:'right'}}>
                                    {
                                        (() => {
                                            if(!this.state.isShowfilter_save_rus){
                                                return(<div style={{float: "right", cursor: "pointer",fontSize: "20px",color: "#e4e4e4"}} onClick={this.toggleFilter_save_rus}>
                                                            <IoIosArrowDown />
                                                        </div>)
                                            }else{
                                                return(<div style={{float: "right", cursor: "pointer",fontSize: "20px",color: "#e4e4e4"}} onClick={this.toggleFilter_save_rus}>
                                                            <IoIosArrowUp />
                                                        </div>)
                                            }
                                        })()
                                    }
                                </div>
                                </EmailTopHeader>
                                {
                                    (() => {
                                        if(this.state.isShowfilter_save_rus){
                                            return(<AdvancedFilterSaveRus doctype={this.state.doctype} regulatorObject={this.state.regulatorObject} filterSubmitValue={this.filterSubmitSaveRus}/>)
                                        }
                                    })()
                                }
                                <CSLTable
                                    processData={this.processMysaveRUTaskData}
                                    tableRows={10}
                                    headerText={''}
                                />
                                <div style={{float: "left"}}>
                                {
                                    (() => {
                                        if(this.state.saveRCtasks.length !== 0){
                                            return(<ExportButton onClick={this.exportToExcel('saved_rus')}>Export</ExportButton>)
                                        }
                                    })()
                                }
                                </div>
                                <div style={{clear: "both"}}></div>
                            </div>
                        </PendingInnerContainer>
                    </PendingContainer>)
                }
            })()
        }
        {
            (() => {
                if('cur_view' in this.props && this.props.cur_view === 'my_rus'){
                    return(<PendingContainer>
                        <PendingInnerContainer>
                            <div style={{marginTop: "20px"}}>
                                <EmailTopHeader style={{display: "inline-block" , fontWeight: 700}}>My Regulatory Updates
                                <div style={{float:'right'}}>
                                {
                                    (() => {
                                        if(!this.state.isShowfilter_my_rus){
                                            return(<div style={{float: "right", cursor: "pointer",fontSize: "20px",color: "#e4e4e4"}} onClick={this.toggleFilter_my_rus}>
                                                        <IoIosArrowDown />
                                                    </div>)
                                        }else{
                                            return(<div style={{float: "right", cursor: "pointer",fontSize: "20px",color: "#e4e4e4"}} onClick={this.toggleFilter_my_rus}>
                                                        <IoIosArrowUp />
                                                    </div>)
                                        }
                                    })()
                                }
                                </div>
                                </EmailTopHeader>
                                {
                                    (() => {
                                        if(this.state.isShowfilter_my_rus){
                                            return(<AdvancedFilterMyRus doctype={this.state.doctype} regulatorObject={this.state.regulatorObject} filterSubmitValue={this.filterSubmitMyRus}/>)
                                        }
                                    })()
                                }
                                <CSLTable
                                    processData={this.processShareWithMeTask}
                                    tableRows={10}
                                    headerText={''}
                                />
                                <div style={{float: "left"}}>
                                {
                                    (() => {
                                        if(this.state.shareWithMeRCTasks.length !== 0){
                                            return(<ExportButton onClick={this.exportToExcel('my_rus')}>Export</ExportButton>)
                                        }
                                    })()
                                }
                                </div>
                                <div style={{clear: "both"}}></div>
                            </div>
                        </PendingInnerContainer>
                    </PendingContainer>)
                }
            })()
        }
        {
            (() => {
                if('cur_view' in this.props && this.props.cur_view === 'my_tasks'){
                    return(<PendingContainer>
                        <PendingInnerContainer>
                            <div style={{marginTop: "20px"}}>
                                <EmailTopHeader style={{display: "inline-block" , fontWeight: 700}}>My Tasks
                                <div style={{float:'right'}}>
                                {
                                    (() => {
                                        if(!this.state.isShowfilter_my_tasks){
                                            return(<div style={{float: "right", cursor: "pointer",fontSize: "20px",color: "#e4e4e4"}} onClick={this.toggleFilter_my_tasks}>
                                                        <IoIosArrowDown />
                                                    </div>)
                                        }else{
                                            return(<div style={{float: "right", cursor: "pointer",fontSize: "20px",color: "#e4e4e4"}} onClick={this.toggleFilter_my_tasks}>
                                                        <IoIosArrowUp />
                                                    </div>)
                                        }
                                    })()
                                }
                                </div>
                                </EmailTopHeader>
                                {
                                    (() => {
                                        if(this.state.isShowfilter_my_tasks){
                                            return(<AdvancedFilterMyTasks doctype={this.state.doctype} regulatorObject={this.state.regulatorObject} filterSubmitValue={this.filterSubmitMyTasks}/>)
                                        }
                                    })()
                                }
                                <CSLTable
                                    processData={this.processFollowupTaskData}
                                    tableRows={10}
                                    headerText={''}
                                />
                                <div style={{float: "left"}}>
                                {
                                    (() => {
                                        if(this.state.followupRCtasks.length !== 0){
                                            return(<ExportButton onClick={this.exportToExcel('my_tasks')}>Export</ExportButton>)
                                        }
                                    })()
                                }
                                </div>
                                <div style={{clear: "both"}}></div>
                            </div>
                        </PendingInnerContainer>
                    </PendingContainer>)
                }
            })()
        }
        {
            (() => {
                if(this.state.modal_view !== null){
                    switch(this.state.modal_view) {
                        case 'view_auto_forword_feeds' : return <div><InactiveOverlay /><AutoForwordFeedModal closeModal={this.closeModal} isDismissClickOnTable={this.state.isDismissClickOnTable} isShowDismissalDropdown={this.state.isShowDismissalDropdown}/></div>; break;
                        case 'view_auto_forword_feeds_gc' : return <div><InactiveOverlay /><AutoForwordFeedModalForGC closeModal={this.closeModal} isDismissClickOnTable={this.state.isDismissClickOnTable} isShowDismissalDropdown={this.state.isShowDismissalDropdown}/></div>; break;
                        case 'taskModal' : return <div><InactiveOverlay /><RegulatoryClientModal closeModal={this.closeModal}/></div>; break;
                        case 'view_auto_froward_dismissed_task' : return <div><InactiveOverlay /><AutoForWardDismissTaskModal closeModal={this.closeModal}/></div>; break;
                        case 'view_dismissed_task' : return <div><InactiveOverlay /><DismissTaskModal closeModal={this.closeModal}/></div>; break;
                        case 'saveAutoFrowardtaskModal' : return <div><InactiveOverlay /><SaveAutoForWardRuModal closeModal={this.closeModal}/></div>; break;
                        case 'view_mysave_task' : return <div><InactiveOverlay /><SaveSelectRUModal closeModal={this.closeModal}/></div>; break;
                        case 'sharetaskModal' : return <div><InactiveOverlay /><RegulatoryShareModal closeModal={this.closeModal}/></div>; break;
                        case 'view_followup_task' : return <div><InactiveOverlay/><FollowupTaskModal closeModal={this.closeModal}/></div>; break;
                        case 'edit_followup_task' : return <div><InactiveOverlay/><EditFollowupTaskModal closeModal={this.closeModal}/></div>; break;
                    }
                }
            })()
        }
        <AlertBox alertParam = {this.state.alert_param}/>
        </div>);
  }
}

export default AllRus;
